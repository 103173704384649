import React, { useEffect } from 'react';
import { Dropdown } from 'antd';
import { useChangeLocale } from '../utils/i18n';
import { useSelector, useDispatch } from 'react-redux';
import { setLocale } from '../actions/locale';
import { stroeTypes } from '../reducers/configureStore';
import glob_icon from '../assets/images/globe.png';
function LocaleDropdown() {
    const changeLanguage = useChangeLocale();
    const locale = useSelector(
        (state: stroeTypes) => state.localeReducer);
    const dispatch = useDispatch();

    const items = [
        { label: '中文', key: 'zh-TW' },
        { label: 'English', key: 'en' },
        { label: 'Japanese', key: 'ja' },
        { label: 'Korea', key: 'kr' },
    ];
    const getTitle = (lang: string) => {
        for (let i = 0; i < items.length; i++) {
            if (lang === items[i].key) {
                return items[i].label;
            }
        }
        return "English";
    }
    const handleClick = (lang: string) => {
        dispatch(setLocale(lang, getTitle(lang)));
    }
    useEffect(() => {
        if (!locale?.locale) {
            dispatch(setLocale(navigator.language, getTitle(navigator.language)))
        }
        locale?.locale && changeLanguage(locale.locale);
    }, [locale])
    return (
        <Dropdown
            className='locale px-2 gap-0 py-2'
            menu={{
                items,
                selectable: true,
                onSelect: ({ key }) => {
                    handleClick(key);
                }
            }}
            trigger={['click']}
        >
            <a
                className="ant-dropdown-link block gradient-text"
                href="#"
                style={{
                    display: "flex",
                    alignItems: "center"
                }}
            >
                <img className='h-[24px] aspect-[1/1]' src={glob_icon} alt="" />
                <span className='text-base ml-2 gradient-text'>
                    {locale.title || "English"}
                </span>
            </a>
        </Dropdown>
    )
}

export default LocaleDropdown;
import axios from 'axios';
import { StorageKey, AB_test_API_HOST } from '../core/config';

const instance = axios.create(); //{withCredentials : true}

const API_TIMEOUT = 10000;



instance.interceptors.request.use(
  function (config: any) {
    config.timeout = API_TIMEOUT;
    const token = localStorage.getItem(StorageKey.TOKEN_KEY);
    // console.log('axios token: ', token);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error: any) {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (res: any) => {
    return Promise.resolve(res);
  },
  (err: any) => {
    return Promise.reject(err.response);
  },
);

export const request = (method: string, url: string, data: any) => {

  const finalUrl = `https://${AB_test_API_HOST}/${url}`;

  return new Promise((resolve, reject) => {
    switch (method) {
      case 'post':
        instance
          .post(finalUrl, data)
          .then((res: any) => {
            resolve(res);
          })
          .catch((err: any) => {
            reject(err?.data ?? false);
          });
        break;
      case 'put':
        instance
          .put(finalUrl, data)
          .then((res: any) => {
            resolve(res);
          })
          .catch((err: any) => {
            reject(err?.data ?? false);
          });
        break;
      case 'get':
        instance
          .get(finalUrl, { params: data })
          .then((res: any) => {
            resolve(res);
          })
          .catch((err: any) => {
            reject(err?.data ?? false);
          });
        break;
      case 'delete':
        instance
          .delete(finalUrl, { data })
          .then((res: any) => {
            resolve(res);
          })
          .catch((err: any) => {
            reject(err?.data ?? false);
          });
        break;
    }
  });
};

export default instance;

import React, { useState, useEffect, useRef } from 'react';
import {
    Layout,
    Space,
    Button,
    Row,
    message,
    Pagination,
    Avatar
} from 'antd';
import { useSearchParams } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { useGetLocaleStr } from '../utils/i18n';
import { VideoInfo, Tag } from '../model/videoInfoModel';
import * as mediaAPI from '../api/MediaApi';
import ReactPlayer from 'react-player';
import VideoCard from '../components/video_card';
import { stroeTypes } from './../reducers/configureStore';
import { useSelector } from 'react-redux';
import _ from "lodash";
import '../assets/css/main_home.css';
import '../styles/Home.css';
import avatar_img from './../assets/images/new_avatar.png';

const { Content } = Layout;

function Service() {

    const isLogin = useSelector((state: stroeTypes) => state.isLoggedReducer);

    useEffect(() => {
    }, []);

    return (
        <div className='bg-[#737373] px-6 py-8'>
            <div className='w-full min-h-[600px] bg-[#110E0C] rounded-2xl'>

                <div className='flex flex-col py-2'>

                    <div className='flex items-center pr-16 pl-4 py-1'>
                        {!isLogin?.isLogin && <img src={avatar_img} alt='avatar_img' className="h-[40px] aspect-[1/1] rounded-full" />}
                        {isLogin?.isLogin && <img src={_.get(isLogin, 'data.user_info.avatar_image_slug', '')} alt='avatar_img' className="h-[40px] aspect-[1/1] rounded-full" />}
                        <p style={{ color: '#FFFFFF', margin: '0' }} className='gradient-text'>
                            Q：怎麼一樣10枚MT幣每次價格都不太一樣？
                        </p>
                    </div>

                    <div className='flex items-center justify-end pr-4 pl-16 py-1'>
                        <p style={{ color: '#FFFFFF', margin: '0' }} className='gradient-text'>
                            A：您好，MT幣的單位為美金喔！每天會根據匯率的不同而有起伏，並不影響您想運動改變自我的現
                            在這個的最佳時刻喔！
                        </p>
                        <img src={avatar_img} alt='avatar_img' className="w-[40px] aspect-[1/1] rounded-full" />
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Service;

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/css/font.css';
import './assets/css/payment.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';

import store, { persistor } from './reducers/configureStore';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    returnNull: false,
    fallbackLng: 'en',
    lng: navigator.language || 'en',
    interpolation: {
      escapeValue: false,
    },
  });

root.render(
  <Provider store={store}>
    <Suspense>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Suspense>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

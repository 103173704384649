import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from "lodash";
import { Layout, Input, Button, Space, Divider} from 'antd';
import MyContext from '../../utils/context';
import { Dropdown } from 'antd';
import Swal from 'sweetalert2'
import { stroeTypes } from '../../reducers/configureStore';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLogin } from '../../actions/login';
import { EndPoint } from '../../core/config';
import Member_Dropdown from '../Member_Dropdown'

import LocaleDropdown from '../locale_dropdown';
import HeaderImageBtnItem from './HeaderImageBtnItem';

import avatar_img from '../../assets/images/new_avatar.png';
import sidebar_cart_icon from '../../assets/images/sidebar_cart_icon.png';
import header_login_icon from '../../assets/images/header_login.png';
import member_service from '../../assets/images/member_service.png';
import member_setting from '../../assets/images/member_setting.png';

import '../../styles/Header.css';
import '../../assets/css/member_dropdown.css';
import '../../assets/css/video_search_bar.css';
import { useGetLocaleStr } from '../../utils/i18n';

const { Search } = Input;
const { Header } = Layout;

const searchWrapper = document.querySelector('.search-input');
const inputBox = searchWrapper?.querySelector('input');
const suggBox = searchWrapper?.querySelector('.autocom-box');
const icon = (searchWrapper?.querySelector('.icon') as HTMLButtonElement) || null;
const linkTag = searchWrapper?.querySelector('a');
let webLink;

const suggestions = [
    'Channel',
    'CodingLab',
    'CodingNepal',
    'YouTube',
    'YouTuber',
    'YouTube Channel',
    'Blogger',
    'Bollywood',
    'Vlogger',
    'Vechiles',
    'Facebook',
    'Freelancer',
    'Facebook Page',
    'Designer',
    'Developer',
    'Web Designer',
    'Web Developer',
    'Login Form in HTML & CSS',
    'How to learn HTML & CSS',
    'How to learn JavaScript',
    'How to become Freelancer',
    'How to become Web Designer',
    'How to start Gaming Channel',
    'How to start YouTube Channel',
    'What does HTML stands for?',
    'What does CSS stands for?',
];

function HeaderItem(props: { name: string; img_src: any; img_alt: string; url: string }) {
    const navigate = useNavigate();
    const context = useContext(MyContext);

    return (
        <>
            <Button
                type='text'
                style={{
                    height: '100%',
                    textAlign: 'center',
                    maxHeight: '100%',
                    alignContent: 'center',
                    marginRight: '10px',
                    maxWidth: '70px',
                    minWidth: '80px',
                    padding: '0',
                    marginTop: '0',
                    marginBottom: '0',
                    marginLeft: '0',
                    borderRadius: '10px',
                }}
                onClick={() => {
                    if (props.name !== '會員登入') {
                        navigate(props.url);
                    } else {
                        context.setIsLogin(true);
                    }
                }}
            >
                <img
                    style={{
                        display: 'block',
                        margin: 'auto',
                    }}
                    width={30}
                    src={props.img_src}
                    alt={props.img_alt}
                />
                <p
                    style={{
                        margin: '0',
                        padding: '0',
                        lineHeight: '100%',
                        color: '#FFFFFF',
                        marginTop: '5px',
                    }}
                >
                    {props.name}
                </p>
            </Button>
        </>
    );
}

function DropDownMenuItem(props: {
    name: string;
    img_src: any;
    img_alt: string;
    url: string;
    onClick: any;
}) {
    const navigate = useNavigate();
    return (
        <Button className='flex p-2 items-center h-fit'
            onClick={() => {
                if(props.url == null) props.onClick(); else navigate(props.url);
            }}
            type='text'
        >
            <div className='w-full flex items-center'>
                <img className='h-[24px] aspect-square'
                    src={props.img_src}
                    alt={props.img_alt}
                />
                <div className='text-base ml-2 gradient-text'>{props.name}</div>
                <div className='flex gradient-text grow items-center justify-end text-xl'>&rsaquo;</div>
            </div>
        </Button>
    );
}

function MainHeader(props: { layout_type?: string }) {

    const navigate = useNavigate();
    const [endpoint, setEndpoint] = useState(EndPoint.GUEST_MAIN);
    const isLogin = useSelector((state: stroeTypes) => state.isLoggedReducer);
    const dispatch = useDispatch();
    const getLocaleStr = useGetLocaleStr();

    const showLogout = () => {
        console.log(['logout', isLogin]);
    };

    useEffect(() => {
        if (isLogin) {
            setEndpoint(EndPoint.MEMBER_MAIN);
        }
    }, [isLogin]);

    return (
        <Header
            style={{
                backgroundColor: '#110e0c',
                height: '70px',
                width: '100%',
            }}
            className='navigation px-1'
        >
            <div className='flex justify-between h-full'>
                {props.layout_type && props.layout_type == 'no-search' ? (
                    <div className=''></div>
                ) : (
                    <div className='flex items-center w-3/5 px-2'>
                        <Search
                            className='search_box'
                            onSearch={(word: string) => {
                                navigate(`${endpoint}?search_query=${word}`);
                            }}
                        />
                    </div>
                )}

                <div className='flex justify-center items-center'>
                    {isLogin && (
                            <Dropdown className='h-fit'
                                trigger={['click']}
                                dropdownRender={() => (
                                    <div
                                        style={{
                                            backgroundColor: '#1a1a1a',
                                            borderRadius: '5px',
                                            boxShadow: 'none',
                                        }}
                                    >

                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div className='h-2'></div>
                                            <LocaleDropdown/>
                                            <DropDownMenuItem
                                                name='客服'
                                                img_src={member_service}
                                                img_alt='member_service'
                                                url='/service'
                                                onClick={() => { }}
                                            />
                                            {
                                                isLogin.isLogin ? (
                                                    <DropDownMenuItem
                                                        name={getLocaleStr("title-logout")}
                                                        img_src={member_setting}
                                                        img_alt='member_service'
                                                        url={null}
                                                        onClick={() => {
                                                            dispatch(setLogin(false, null, null, null));
                                                        }}
                                                    />
                                                 ) : (
                                                    <DropDownMenuItem
                                                        name={getLocaleStr("title-login")}
                                                        img_src={member_setting}
                                                        img_alt='member_login'
                                                        url={"/login"}
                                                        onClick={() => {
                                                            dispatch(setLogin(false, null, null, null));
                                                        }}
                                                    />
                                                )
                                            }
                                            
                                        </div>
                                    </div>
                                )}
                            >

                                <Button type='text'>
                                    <Space>
                                        {!isLogin?.isLogin && <img src={avatar_img} alt='avatar_img' className="h-[40px] aspect-[1/1] rounded-full" />}
                                        {isLogin?.isLogin && <img src={_.get(isLogin, 'data.user_info.avatar_image_slug', '')} alt='avatar_img' className="h-[40px] aspect-[1/1] rounded-full" />}
                                        <p style={{ color: '#FFFFFF', margin: '0' }} className='gradient-text'>
                                            {isLogin.mail != undefined && isLogin.mail.split('@')[0]}
                                        </p>
                                    </Space>
                                </Button>

                            </Dropdown>
                    )}
                    {!isLogin && (
                        <>
                            <HeaderItem
                                name='會員登入'
                                img_src={avatar_img}
                                img_alt='avatar_img'
                                url={`#`}
                            />
                        </>
                    )}
                </div>
            </div>
        </Header>
    );
}

// if (inputBox != null) {
//     // if user press any key and release
//     inputBox.onkeyup = (e: any) => {
//         const userData = e.target.value; //user enetered data
//         let emptyArray = [];
//         if (userData && icon != null) {
//             icon.onclick = () => {
//                 webLink = `https://www.google.com/search?q=${userData}`;
//                 linkTag?.setAttribute('href', webLink);
//                 linkTag?.click();
//             };
//             emptyArray = suggestions.filter((data: any) => {
//                 //filtering array value and user characters to lowercase and return only those words which are start with user enetered chars
//                 return data.toLocaleLowerCase().startsWith(userData.toLocaleLowerCase());
//             });
//             emptyArray = emptyArray.map((data: any) => {
//                 // passing return data inside li tag
//                 return (data = `<li>${data}</li>`);
//             });
//             searchWrapper?.classList.add('active'); //show autocomplete box
//             showSuggestions(emptyArray);
//             const allList = suggBox?.querySelectorAll('li');
//             if (allList != null) {
//                 for (let i = 0; i < allList.length; i++) {
//                     //adding onclick attribute in all li tag
//                     allList[i].setAttribute('onclick', 'select(this)');
//                 }
//             }
//         } else {
//             searchWrapper?.classList.remove('active'); //hide autocomplete box
//         }
//     };
// }

// function select(element: any) {
//   if (inputBox != null && searchWrapper != null) {
//     let selectData = element.textContent;
//     inputBox.value = selectData;
//     icon.onclick = () => {
//       webLink = `https://www.google.com/search?q=${selectData}`;
//       linkTag?.setAttribute('href', webLink);
//       linkTag?.click();
//     };
//     searchWrapper.classList.remove('active');
//   }
// }

function showSuggestions(list: any) {
    if (suggBox != null) {
        let listData;
        if (!list.length) {
            const userValue = inputBox?.value;
            listData = `<li>${userValue}</li>`;
        } else {
            listData = list.join('');
        }
        suggBox.innerHTML = listData;
    }
}

export default MainHeader;

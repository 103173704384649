
import React from "react";
import { Col, Avatar } from "antd";
import { UserOutlined } from '@ant-design/icons';
import { VideoInfo } from "../model/videoInfoModel";

const VideoCard = (props: { video: VideoInfo }) => {
    return (
        <Col key={props.video.title} xs={24} sm={24} md={12} lg={12} xl={6}>
            <div className='video_block' onClick={()=> {location.href= `/watch?v=${props.video.uuid}`}}>
                <div className='video_block_top'>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '250px',
                        backgroundColor: '#000000',
                        borderRadius: '10px',
                        cursor: "pointer"
                    }}
                >
                    <img
                        src={props.video.present_image_slug}
                        alt=''
                        style={{
                            objectFit: 'contain',
                            height: '100%',
                            width: '100%',
                        }}
                    />
                </div>
                {/*<div className='video_block_top_bot_left'>
                    <div
                        style={{
                            marginLeft: '5px',
                            color: "#FFFFFF"
                        }}
                    >
                        {props.video.like_count}
                    </div>
                </div>
                    */}
                <div className='video_block_top_bot_right'>
                    <div
                        style={{
                        height: '35px',
                        width: '60px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: "#ffffff",
                        borderRadius: '5px',
                        }}
                    >
                    {props.video.video_time !== "00:00" && props.video.video_time /*FIXME should get youtube video metadata */}
                    </div>
                </div>
                </div>

                <div className='video_block_desc'>
                <div className='video_block_desc_left'>
                {props.video?.user_info.avatar_image_slug != "https://storagprops.video.googleapis.com/morld_storge/" ?
                    <Avatar size={45} src={<img src={props.video?.user_info.avatar_image_slug} alt="avatar" />} /> : <Avatar size={45} icon={<UserOutlined />} />}
                </div>
                <div className='video_block_desc_right' style={{marginLeft: "10px"}}>
                <a
                    style={{ textDecoration: 'none' , fontSize: '17px', width: 'fit-content', }}
                    href={`/watch?v=${props.video.uuid}`}
                    className='video_block_desc_left_top text-gray-300 video-desc'
                    >
                    {props.video.title}
                    </a>
                </div>
                </div>
            </div>
        </Col>
    )
}

export default VideoCard; 

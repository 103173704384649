export const SETLOGIN = 'SETLOGIN';

interface SetLOGINI {
  type: typeof SETLOGIN;
  payload: {
    isLogin: boolean;
    mail: string;
    token: string;
    data: object;
  };
}

export const setLogin = (isLogin: boolean, mail: string, token: string, data: object): SetLOGINI => ({
  type: SETLOGIN,
  payload: {
    isLogin,
    mail,
    token,
    data
  },
});

export type loginActionTypes = SetLOGINI;

import React from 'react';

interface IContext {
  isLogin: boolean;
  setIsLogin: (checked: boolean) => void;
  mail: string;
  setMail: (checked: string) => void;
}

/* eslint-disable */
const MyContext = React.createContext<IContext>({
  isLogin: false,
  setIsLogin: (checked: boolean) => {},
  mail: '',
  setMail: (checked: string) => {},
});
/* eslint-enable */

export default MyContext;

export const SetLocale = "SET_LOCALE";

interface SetLocaleI {
    type: typeof SetLocale;
    payload: {
      locale: string;
      title: string;
    };
}

export const setLocale = (locale: string, title: string): SetLocaleI => ({
    type: SetLocale,
    payload: {
        locale: locale,
        title: title
    }
});

export type localeActionTypes = SetLocaleI;
import React, { useState, useEffect, useId, useRef } from 'react';
import { Layout, Space, Row, Col, message, Pagination } from 'antd';
import { useGetLocaleStr } from '../utils/i18n';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from "lodash";
import { stroeTypes } from '../reducers/configureStore';
import Swal from 'sweetalert2'
//css
import '../assets/css/LoginPage.css';
import '../assets/css/main_home.css';
import '../styles/Home.css';
import 'react-spring-bottom-sheet/dist/style.css'
import { BottomSheet } from 'react-spring-bottom-sheet'
const { Content } = Layout;
import CountrySelector from "../components/country-picker/selector";
import { COUNTRIES } from "../components/country-picker/countries";
import { SelectMenuOption } from "../components/country-picker/types";
import { SegmentedControl } from 'segmented-control'
import BuyInfo from '../components/BuyInfo';

enum Plan {
    PersonalMonthPlan = "三個月訂閱",
    PersonalYearPlan = "十二個月訂閱",
    ProfessionalPlan = "一個月訂閱"
}

function StorePage() {

    const refPay = useRef(null);
    const [messageApi, contextHolder] = message.useMessage();
    const getLocaleStr = useGetLocaleStr();
    const [data, setData] = useState({'country':'TW'});
    const [showBuyInfo, setShowBuyInfo] = useState<boolean>(false);
    const isLogin = useSelector((state: stroeTypes) => state.isLoggedReducer);
    const navigate = useNavigate();
    const locale = useSelector((state: stroeTypes) => state.localeReducer);

    useEffect(() => {
        console.log(['data',data])
    }, [data]);

    useEffect(() => {
        console.log(['locale',locale])
        setData(prevState => ({...prevState, lang: (locale.locale)}));
    }, [locale]);

    useEffect(() => {
        console.log(['isLogin',isLogin])
    }, [isLogin]);

    const buy = async (_plan: string) => {
        if (_.get(isLogin, 'isLogin', false) == false) {
            let timerInterval;
            Swal.fire({
                title: "請登入後再購買",
                html: "(3秒後自動跳轉)",
                timer: 3000,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading();
                },
                willClose: () => {
                    clearInterval(timerInterval);
                }
            }).then((result) => {
                if (result.dismiss === Swal.DismissReason.timer) {
                    navigate('/login')
                }
            });
            return;
        }
        let text = "";
        switch (_plan) {
            case Plan.PersonalMonthPlan:
                text = getLocaleStr("desc-plan-text-personal-month");
                break;
            case Plan.PersonalYearPlan:
                text = getLocaleStr("desc-plan-text-personal-year");
                break;
            case Plan.ProfessionalPlan:
                text = getLocaleStr("desc-plan-text-professional");
                break;
            default:
                break;
        }

        Swal.fire({
            html: "<div class='flex justify-start text-left'>"+text+"</div>",
            background:'#1b1b1b', 
            color:"#ffffff",
            confirmButtonText: "Agree", 
            customClass:{
                confirmButton: 'bg-[#ffffff] text-[#1b1b1b] rounded-md text-xl px-3 py-1',
            },
            buttonsStyling:false
        }).then((result) => {
            console.log(result)
            if (result.isConfirmed) {
                setData(prevState => ({...prevState, email: (_.get(isLogin, 'mail', ''))}));
                setData(prevState => ({...prevState, plan: (_plan)}));
                setShowBuyInfo(true)
            }
        });
    };

    return (
        <Content
            style={{
                backgroundColor: '#1b1b1b',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                minHeight: 'unset',
            }}
        >
            <BuyInfo show={showBuyInfo} onDismiss={() => { setShowBuyInfo(false) }} onDone={async(res) => {
                await setData(prevState => ({
                    ...prevState,
                    carriers: (res.carriers),
                    tax_id: (res.tax_id),
                    invoice_type: (res.invoice_type),
                    name: (res.name),
                    nid: res.nid,
                    country: res.country
                }));
                refPay.current.submit()
            }} />
          
            {contextHolder}
            <form  ref={refPay} className="hidden" action="https://api.morld.com.tw/checkout/subscription" method="post">
                <input type="text" name="lang" value={_.get(data,'lang','')} />
                <input type="text" name="type" value={_.get(data,'plan','')} />
                <input type="text" name="email" value={_.get(data,'email','')} />
                <input type="text" name="tax_id" value={_.get(data,'tax_id','')} />
                <input type="text" name="nid" value={_.get(data,'nid','')} />
                <input type="text" name="invoice_type" value={_.get(data,'invoice_type','')} />
                <input type="text" name="carriers" value={_.get(data,'carriers','')} />
                <input type="text" name="country" value={_.get(data,'country','')} />
                <input type="submit" />
            </form>
            <div style={{ padding: '20px 10px 0', height: '100%', overflow: 'auto' }}>
                <div className='d-flex flex-column justify-content-center align-items-center position-relative font-zenDots'>
                    <div className='row mb-1 mt-4 w-100'>
                    <div className='col-xl'>
                        <div className='card content-box-payment'>
                        <div className='card-body'>
                            <div className='container-payment '>
                            <div className='box-plan'>
                                <div className='gradient-box-one'>
                                <div className='gradient-box-two'>
                                    <p className='text-box-plan'>{getLocaleStr('text-package-plantitle-1')}</p>

                                    <p className='text-gray-300 content-box-plan'>
                                    ✓ {getLocaleStr('text-package-plancontent-1')}
                                    <br />
                                    ✓ {getLocaleStr('text-package-plancontent-2')}
                                    <br />
                                    ✓ {getLocaleStr('text-package-plancontent-3')}
                                    <br />
                                    ✓ {getLocaleStr('text-package-plancontent-4')}
                                    <br />✓ {getLocaleStr('text-package-plancontent-5')}
                                    </p>
                                </div>
                                </div>
                            </div>
                            </div>
                            {/* limit  */}
                            <div className='mt-4'>
                            {/* limit  */}
                            <div className='gap-5 justify-content-start d-flex flex-column flex-md-row flex-lg-row gap-md-10 gap-lg-4'>


                            <div className='mb-1 h-434 d-flex flex-column justify-content-start shadow-xl w-100'>
                                <div className=' rounded bg-gradient-b p-1 gradientPersonal'>
                                    <div className=' d-flex flex-column justify-content-start bg-black p-3 rounded'>

                                    <div className="flex">
                                        <p className='bg-[#FFE666] rounded-lg px-3 py-2'>{getLocaleStr('text-package-name-plan')}</p>
                                    </div>
                                    

                                    <div className='text-gray-300 h-60 px-2 flex justify-content-start items-center'>
                                        <div>
                                        • {getLocaleStr('text-package-plan1-1')}
                                        </div>
                                    </div>

                                    <div
                                        className="text-black no-underline"
                                        onClick={() => buy(Plan.PersonalMonthPlan)}
                                    >
                                        <div className='whitespace-pre py-1 text-buy text-uppercase bg-[#FFE666] rounded-2xl'>
                                        {getLocaleStr('text-price-month')}
                                        </div>
                                    </div> 


                                    </div>
                                </div>
                                </div>

                                <div className='mb-1 h-434 d-flex flex-column justify-content-start shadow-xl w-100'>
                                <div className=' rounded bg-gradient-b p-1 gradientPersonalYear'>
                                    <div className=' d-flex flex-column justify-content-start bg-black p-3 rounded'>

                                    <div className="flex">
                                        <p className='bg-[#F55252] rounded-lg px-3 py-2'>{getLocaleStr('text-package-name-year')}</p>
                                    </div>
                                    

                                    <div className='text-gray-300 h-60 px-2 flex justify-content-start items-center'>
                                        <div>
                                        • {getLocaleStr('text-package-plan2-1')}
                                        </div>
                                    </div>

                                    <div
                                        className="text-black no-underline"
                                        onClick={() => buy(Plan.PersonalYearPlan)}
                                    >
                                        <div className='whitespace-pre py-1 text-buy text-uppercase bg-[#F55151] rounded-2xl'>
                                            {getLocaleStr('text-price-year')}
                                        </div>
                                    </div>


                                    </div>
                                </div>
                                </div>

                                <div className='mb-1 h-434 d-flex flex-column justify-content-start shadow-xl w-100'>
                                    <div className='rounded bg-gradient-b p-1 grandientProfesional'>
                                        <div className='d-flex flex-column justify-content-start bg-black p-3 rounded'>
                                        <div className="flex">
                                            <p
                                            className='rounded-lg px-3 py-2'
                                            style={{
                                                background: 'linear-gradient(to right, #ffee17, #e63c2b)',
                                            }}
                                            >
                                            {getLocaleStr('text-package-name-pro')}
                                            </p>
                                        </div>

                                        <div className='text-gray-300 h-60 px-2 flex justify-content-start items-center'>
                                            <div>
                                            • {getLocaleStr('text-package-plan3-1')}
                                            </div>
                                        </div>

                                        <a
                                            className='text-black no-underline'
                                            onClick={() => buy(Plan.ProfessionalPlan)}
                                        >
                                            <div
                                                style={{
                                                    background: 'linear-gradient(to right, #ffee17, #e63c2b)',
                                                }}
                                                className='whitespace-pre py-1 text-buy text-uppercase rounded-2xl'
                                            >
                                                {getLocaleStr('text-price-every-month')}
                                            </div>
                                        </a>

                                        </div>
                                    </div>
                                    </div>

                                </div>
                            {/* limit  */}
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </Content>
    );
}

export default StorePage;

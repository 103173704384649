export const urlYoutube = "https://www.youtube.com/channel/UChEgJWt7fw53IRGi9aBEzSA";
export const urlInstagram = " https://instagram.com/morld.tw?igshid=YzAwZjE1ZTI0Zg==";
export const urlFacebook = "https://www.facebook.com/profile.php?id=61553603939471&is_tour_completed=true";
export const languageList = ["zh-TW", "en", "ja"];
export const AB_test_API_HOST = 'abapi.morld.com.tw';

export enum StorageKey {
    TOKEN_KEY = "TOKEN",
    SHOPPING_CART = "shoppingCart"
}


export enum EndPoint {
    SHOPPING_CART = "/shopping-cart",
    GUEST_MAIN = "/guest_main",
    MEMBER_MAIN = "/member_main"
}
import * as restAPI from '../utils/axiosManager';
import { VideoComment } from '../model/videoInfoModel';
/*
Promise 的意義 : 如字面的意思就代表 承諾。

承諾的未來當你拿到一個 Promise 的時候，代表在未來中這個 Promise 可能會有幾種狀況發生

(1).承諾 被兌現 (fulfilled) : 用 resolve() 來兌現

(2).承諾 被打破 (rejected) : 用 reject() 來表示失敗

(3). 承諾 一直沒有回應 (pending) : 一直沒有回傳

# 兌現 (fulfilled)，通常以 resolve 命名該參數 : 當我們完成動作時，就呼叫 resolve() 來兌現我們的承諾
# 拒絕 (rejected)，通常以 reject 命名該參數   : 當我們動作失敗時，就呼叫 reject() 來打破我們的承諾
*/


//取得指定影片留言的API
const getFilmComments = (uuid : string) => {
    return new Promise((resolve, reject) => {
        const url = `api/film_comment?film_id=${uuid}`; //查詢之API
        restAPI
        .request('get', url, {})
        .then((res: any) => {
            const data = res.data; //取得資訊
            if (data.error !== '') { //如果有錯誤的話
                reject(data.error);
            }
            const comment_list: VideoComment[] = [];
            for (let i = 0; i < data.data.length; i++) {
                comment_list.push(new VideoComment(data.data[i]));
            }
            resolve(comment_list);
        })
        .catch((err: any) => {
            reject(err);
        });

    });
}

const postFilmComment = (uuid:string,body:string) => {
    return new Promise((resolve, reject) => {
        const url = `api/film_comment`; //註冊之API

        const params: any = {};
        params['film_id'] = uuid;
        params['message'] = body;

        restAPI
        .request('post', url, params)
        .then((res: any) => {
            const data = res.data; //取得資訊
            if (data.error !== '') { //如果有錯誤的話
                reject(data.error);
            }
            resolve(data.data);
        })
        .catch((err: any) => {
            reject(err);
        });

    });
}

export {
    getFilmComments,
    postFilmComment,
}
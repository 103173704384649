import React, { useState, useEffect, useRef } from 'react';
import { Layout, message, Row, Button, Col } from 'antd';
import { useSearchParams } from 'react-router-dom';

import Sidebar from '../components/Sidebar';
import MainHeader from '../components/header/MainHeader';
import { VideoInfo } from '../model/videoInfoModel';
import * as mediaAPI from '../api/MediaApi';

import heart_img from '../assets/images/heart.png';

const { Content } = Layout;

function Results() {
  const refMain = useRef(null);
  const [messageApi, contextHolder] = message.useMessage();
  /* eslint-disable */
  const [searchParams, setSearchParams] = useSearchParams();
  /* eslint-enable */

  const [searchQuery, setSearchQuery] = useState<string>();
  const [videoList, setVideoList] = useState<VideoInfo[]>([]);

  useEffect(() => {
    const search_query = searchParams.get('search_query');
    if (search_query) {
      setSearchQuery(search_query);
    }
  }, [searchParams]);

  const sec_to_time = (seconds: string) => {
    const total_sec = Number(seconds);
    const hr = Math.floor(total_sec / 3600);
    const minute = Math.floor((total_sec - hr * 3600) / 60);
    const sec = total_sec - hr * 3600 - minute * 60;
    return hr + ':' + minute + ':' + sec;
  };

  const get_video_list = (search_query: string) => {
    mediaAPI
      .getVideosBySearchQuery(search_query)
      .then((data: any) => {
        setVideoList(data);
      })
      .catch((error: any) => {
        messageApi.error(error);
      });
  };

  useEffect(() => {
    if (searchQuery) {
      get_video_list(searchQuery);
    }
  }, [searchQuery]);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sidebar refMain={refMain}/>
      <Layout style={{ minHeight: '100vh' }}>
        <MainHeader />
        <Content
          style={{
            backgroundColor: '#1b1b1b',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {contextHolder}
          <div style={{ paddingTop: '20px', height: '100%', overflow: 'auto' }}>
            <div style={{ maxWidth: '1200px', margin: 'auto' }}>
              <div className='video_group'>
                <Row>
                  {videoList.map((e: VideoInfo) => {
                    return (
                      <Col key={e.title} xs={24} sm={24} md={24} lg={12} xl={8}>
                        <div className='video_block'>
                          <div className='video_block_top'>
                            <img src={e.present_image_slug} alt='' height='250px' />
                            <div className='video_block_top_bot_left'>
                              <img src={heart_img} alt=''></img>
                              <div>{e.like_count}</div>
                            </div>
                            <div className='video_block_top_bot_right'>
                              <div
                                style={{
                                  height: '35px',
                                  width: '60px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  backgroundColor: 'rgba(12, 12, 12, 0.42)',
                                  borderRadius: '5px',
                                }}
                              >
                                {sec_to_time(e.video_time)}
                              </div>
                            </div>
                          </div>

                          <div className='video_block_desc'>
                            <div className='video_block_desc_left'>
                              <a href={`watch?v=${e.uuid}`} className='video_block_desc_left_top'>
                                {e.title}
                              </a>
                              <p className='video_block_desc_left_bot'>{e.user_info.name}</p>
                            </div>
                            <div className='video_block_desc_right'>
                              <Button
                                style={{
                                  backgroundColor: 'rgba(0, 255, 25, 0.42)',
                                  height: '35px',
                                  border: '0',
                                  width: '60px',
                                }}
                              >
                                購買
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default Results;

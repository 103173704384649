import React, { useState, useEffect } from 'react';
import { Layout, message, Space, Table, Input, Select, Button } from 'antd';

import * as mediaAPI from '../api/MediaApi';
import Sidebar from '../components/Sidebar';
import MainHeader from '../components/header/MainHeader';
import '../assets/css/Table.css';
import { useNavigate } from 'react-router-dom';

const { Content } = Layout;
const { Column } = Table;

interface TableDataType {
  key: React.Key;
  title: string;
  process_status: string;
  video: string;
  permission: boolean;
  status: string;
  sell: boolean;
  publish_date: string;
  view_count: number;
}

interface TableFilter {
  title?: string;
  permission?: string;
  status?: string;
  time?: string;
}

function MemberChannel() {
  const [messageApi, contextHolder] = message.useMessage();
  const [videoList, setVideoList] = useState<TableDataType[]>([]);
  const [pagination, setPagination] = useState<any>({ current: 1, pageSize: 10, total: 0 });

  const [filter, setFilter] = useState<TableFilter>();

  const navigate = useNavigate();

  useEffect(() => {
    get_personal_video(pagination.current);
  }, [pagination.current]);

  const handleTableChange = (pagination: any) => {
    setPagination(pagination);
  };

  const get_personal_video = (page: number) => {
    mediaAPI
      .getPersonalVideos(page)
      .then((data: any) => {
        const total_video = data.data.total;
        const page_size = data.data.per_page;

        const video_list: TableDataType[] = [];
        for (let i = 0; i < data.data.data.length; i++) {
          const video_info = data.data.data[i];
          const row = {} as TableDataType;
          row.key = video_info.uuid;
          row.title = video_info.title;
          row.video = video_info.present_image_slug;
          if (video_info.detail_info) {
            row.permission = video_info.detail_info.publish ? true : false;
            row.status = video_info.detail_info.film_review_status
              ? video_info.detail_info.film_review_status
              : '';
            row.process_status = video_info.detail_info.process_status;
            row.sell = video_info.detail_info.sell;
          }
          row.view_count = video_info.view_count;
          row.publish_date = video_info.update_time;
          video_list.push(row);
        }
        setVideoList(video_list);
        setPagination((prevPagination: any) => ({
          ...prevPagination,
          pageSize: page_size,
          total: total_video,
        }));
      })
      .catch((error: any) => {
        messageApi.error(error);
      });
  };

  const filterVideoList = () => {
    //console.log('TODO search by filter: ', filter);
  };

  const loading_msg_key = 'loading_msg_key';

  const deleteVideo = (key: string) => {
    //console.log('delete key: ', key);

    // const form = new FormData();
    // form.append('uuid', 'sss');
    const data = { uuid: key };

    mediaAPI
      .deleteVideo(data)
      .then(() => {
        messageApi.open({
          key: loading_msg_key,
          type: 'success',
          content: '刪除成功',
          duration: 2,
        });

        window.location.reload();
      })
      .catch((error: any) => {
        //console.log('delete Video error: ', error);
      });
  };

  const CustomEmptyRender = () => {
    return <div style={{ color: '#FFFFFF', minHeight: '100px' }}></div>;
  };

  return (
    <Layout style={{  minHeight: '100vh' }}>
      {contextHolder}
      <Sidebar refMain={null}/>
      <Layout style={{ minHeight: '100vh' }}>
        <MainHeader layout_type='no-search' />
        <Content
          style={{
            backgroundColor: '#1b1b1b',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            className='search_block'
            style={{ height: '100px', display: 'flex', flexDirection: 'row' }}
          >
            <Space style={{ marginLeft: '30px' }}>
              <Input
                style={{ width: 200, height: 40, fontSize: 16 }}
                placeholder='標題'
                onChange={(e: any) => {
                  setFilter({ ...filter, title: e.target.value });
                }}
              />
              <Select
                style={{ width: 120 }}
                size='large'
                onChange={(e: any) => {
                  setFilter({ ...filter, permission: e });
                }}
                placeholder='瀏覽權限'
                options={[
                  { value: 'default', label: '全部' },
                  { value: 'public', label: '公開' },
                  { value: 'draft', label: '草稿' },
                ]}
              />
              <Select
                style={{ width: 120 }}
                size='large'
                onChange={(e: any) => {
                  setFilter({ ...filter, status: e });
                }}
                placeholder='審核狀態'
                options={[
                  { value: 'default', label: '全部' },
                  { value: 'processing', label: '審核中' },
                  { value: 'passed', label: '通過' },
                  { value: 'failed', label: '未通過' },
                ]}
              />
              
              <Button
                size='large'
                onClick={() => {
                  filterVideoList();
                }}
              >
                搜尋
              </Button>
            </Space>
          </div>
          <div
            style={{
              height: '100%',
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
            }}
          >
            <div style={{ flexGrow: '1' }}>
              <Table
                dataSource={videoList}
                style={{ backgroundColor: '#FFFFFF' }}
                pagination={pagination}
                onChange={handleTableChange}
                locale={{
                  emptyText: <CustomEmptyRender />,
                }}
              >
                <Column
                  title='影片'
                  dataIndex='video'
                  key='video'
                  render={(video: string) => (
                    <Space size='middle'>
                      <img src={video} height={60} style={{ borderRadius: '5px' }} />
                    </Space>
                  )}
                />
                <Column title='標題' dataIndex='title' key='title' />
                <Column title='轉檔狀態' dataIndex='process_status' key='process_status' />
                <Column
                  title='瀏覽權限'
                  dataIndex='permission'
                  key='permission'
                  render={(permission: boolean) => (
                    <Space size='middle'>
                      <p style={{ padding: 0, margin: 0 }}>{permission ? '公開' : '不公開'}</p>
                    </Space>
                  )}
                />
                <Column title='審核狀態' dataIndex='status' key='status' />
                <Column title='發布日期' dataIndex='publish_date' key='publish_date' />
                <Column title='觀看次數' dataIndex='view_count' key='view_count' />
                <Column
                  title='操作'
                  key='action'
                  dataIndex='key'
                  render={(key: string) => (
                    <Space size='middle'>
                      <Button
                        type='primary'
                        onClick={() => {
                          //filterVideoList();
                        }}
                      >
                        編輯
                      </Button>
                      <Button
                        type='primary'
                        danger
                        onClick={() => {
                          deleteVideo(key);
                        }}
                      >
                        刪除
                      </Button>
                    </Space>
                  )}
                />
                {/* <Column
                  title='Action'
                  key='action'
                  render={() => (
                    <Space size='middle'>
                      <a>編輯</a>
                    </Space>
                  )}
                /> */}
              </Table>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default MemberChannel;

import React, { useState, useEffect,useRef,useCallback} from 'react';

import fileDownload from 'js-file-download'
import * as compareVideoAPI from '../api/CompareVideoApi'; // video api
import axios from 'axios';
import Swal from 'sweetalert2'
import '../styles/MemberAB.css';

import {
    Layout,
    Row,
    Col,
    Cascader,
    Checkbox,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Radio,
    Select,
    Slider,
    Switch,
    TreeSelect,
    Upload,
    Button,
  } from 'antd';

import { PlusOutlined,UploadOutlined  } from '@ant-design/icons';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';

import "video-react/dist/video-react.css";
import { Player } from 'video-react';


function Member_AB_Layout() {


     // 變數
     const [videoFileA, setVideoAFile] = useState<any>(null); // 影片A
     const [videoFileB, setVideoBFile] = useState<any>(null); // 影片B
     const [fileList, setFileList] = useState<UploadFile[]>();
     
     const [taskID,setTaskID] = useState("");
     //add
     const playerA = useRef<any>(null);
     const playerB = useRef<any>(null);
     const [videoAtime, setVideoAtime] = useState("");
     const [videoBtime, setVideoBtime] = useState("");
     const [videoAurl, setVideoAurl] = React.useState('');
     const [videoBurl, setVideoBurl] = React.useState('');
    
    // const handleAFileChange = (event : any) => {
    //     const file = event.target.files[0];
    //     const url = URL.createObjectURL(file);
    //     setVideoA(url);
    // };
    
    // const handleBFileChange = (event : any) => {
    //     const file = event.target.files[0];
    //     const url = URL.createObjectURL(file);
    //     setVideoB(url);
    // };


    const upload_compare_event = async () => {

        const log = "[upload_compare_event]:";
        // step 1 : check server status
        

        compareVideoAPI
        .searchSystemStatus()
        .then((data: any) => { //正常回應
            console.log(log+" success - "+data);
            if(data == true){
                Swal.fire({
                    title: '系統通知',
                    text: '系統忙碌中，稍後再用',
                    icon: 'error',
                    confirmButtonText: 'OK'
                })
                return
            } else {

                
                if(videoFileA && videoFileB) {
                    compareVideoAPI
                    .uploadCompareVideoTask(videoFileA,videoFileB,videoAtime,videoBtime)
                    .then((data: any) => { //正常回應
                        if(data) {
                            Swal.fire({
                                title: '系統通知',
                                text: '上傳影片成功 - 任務編號 : ' + data.code,
                                icon: 'success',
                                confirmButtonText: 'OK'
                            }) 
                            setFileList([]);
                            setVideoAFile(null);
                            setVideoBFile(null);
                            setVideoAurl("");
                            setVideoBurl("");
                            setVideoAtime("");
                            setVideoBtime("");
                        } else {
                            Swal.fire({
                                title: '系統通知',
                                text: data,
                                icon: 'error',
                                confirmButtonText: 'OK'
                            }) 
                            
                        }
                       
                    })
                    .catch((error: any) => { //異常回應
                        Swal.fire({
                            title: '系統通知',
                            text: error,
                            icon: 'error',
                            confirmButtonText: 'OK'
                        }) 
                    });
                }
                else {
                    Swal.fire({
                        title: '系統通知',
                        text: '上傳檔案未完整，請檢查',
                        icon: 'error',
                        confirmButtonText: 'OK'
                    })
                    return
                }
               
            }

            
        })
        .catch((error: any) => { //異常回應
            console.log(log+" error - "+error);
            Swal.fire({
                title: '系統通知',
                text: error,
                icon: 'error',
                confirmButtonText: 'OK'
            })
            return

        });



        // const film_a = null;
        // const film_b = null;
        // //step 1 : check server status
        // //step 2 : call api
       
    }


    const query_task_event = async (value: string) => {

        console.log(value);

        const log = "[query_task_event]:";

        //step 1 : check server status
        //step 2 : call api
        compareVideoAPI
        .searchCompareVideoTask(value)
        .then((data: any) => { //正常回應
            //no the task id
            if(data.length == 0){
                Swal.fire({
                    title: '系統通知',
                    text: '無此任務代碼',
                    icon: 'error',
                    confirmButtonText: 'OK'
                })
                return;
            } 
            // task is busy
            if(data.length == 1 && data[0].status == 0) {
                Swal.fire({
                    title: '系統通知',
                    text: '該任務未完成',
                    icon: 'error',
                    confirmButtonText: 'OK'
                })
                return;
            }

            Swal.fire({
                title: '系統通知',
                text: '任務編號:'+value+'已完成',
                icon: 'success',
                confirmButtonText: 'OK'
            })
            
        })
        .catch((error: any) => { //異常回應
            console.log(log+" error - "+error);
            Swal.fire({
                title: '查詢任務',
                text: error,
                icon: 'error',
                confirmButtonText: 'OK'
            })
            return;
        });

   

    }

    const download_compare_event = async (video_id : any) => {

        const file_name = "out.mp4";

        const log = "[query_task_event]:";

        console.log("download_compare_event");

        compareVideoAPI
        .searchCompareVideoTask(video_id)
        .then((data: any) => { //正常回應
            //no the task id
            if(data.length == 0){
                Swal.fire({
                    title: '系統通知',
                    text: '無此任務代碼',
                    icon: 'error',
                    confirmButtonText: 'OK'
                })
                return;
            } 
            // task is busy
            if(data.length == 1 && data[0].status == 0) {
                Swal.fire({
                    title: '系統通知',
                    text: '該任務未完成',
                    icon: 'error',
                    confirmButtonText: 'OK'
                })
                return;
            }

            const url =   `https://abapi.morld.com.tw/videos/${data[0].id}_out.mp4`;

            axios.get(url, {
            responseType: 'blob',
            })
            .then((res) => {
                fileDownload(res.data, file_name)
            })

            
        })
        .catch((error: any) => { //異常回應
            console.log(log+" error - "+error);
            Swal.fire({
                title: '系統通知',
                text: error,
                icon: 'error',
                confirmButtonText: 'OK'
            })
            return;
        });

    }

    const handleAFilmRemove = useCallback(() =>{
        setVideoAFile(null);
        setVideoAurl("");            
    }, [])

    const handleBFilmRemove = useCallback(() =>{
        setVideoBFile(null);
        setVideoBurl("");            
    }, [])

    // check Film A
    const handleFilmAFileBeforeload = (file: RcFile) => {

        //檢查是否為影片副檔名
        const isVideo = file.type === 'video/mp4' ; // || file.type === 'video/?' 要判斷多個得話

      
        if(!isVideo) {
            Swal.fire({
                title: '系統通知',
                text: '檔案格式不符合',
                icon: 'error',
                confirmButtonText: 'OK'
            })
            return Upload.LIST_IGNORE;
        } else {

            setVideoAFile (file);
            const url = URL.createObjectURL(file);
            setVideoAurl(url);
        }
        return false;
    
    };

    // check Film B
    const handleFilmBFileBeforeload = (file: RcFile) => {

        //檢查是否為影片副檔名
        const isVideo = file.type === 'video/mp4' ; // || file.type === 'video/?' 要判斷多個得話

        if(!isVideo) {
            Swal.fire({
                title: '系統通知',
                text: '檔案格式不符合',
                icon: 'error',
                confirmButtonText: 'OK'
            })
            return Upload.LIST_IGNORE;
        } else {

            setVideoBFile (file);
            const url = URL.createObjectURL(file);
            console.log("url "+url);
            setVideoBurl(url);
        }
        return false;
    
    };

    return (
        <Layout style={{
            backgroundColor: '#1b1b1b',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            color: '#FFFFFF',
            overflow: 'auto',
          }}>

        <Row  justify="center" align="middle" style={{padding:'10px'}} >
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{ textAlign: 'center'}}>
            <div className='rcorners'>
                <Row  justify="center" align="middle"  >
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{ textAlign: 'center',padding:'10px'}}>
                        <Input     
                            style={{width:'100%'}}
                            className='search_ab_box'
                            placeholder="輸入任務編號"
                            value={taskID}
                            onChange={e => setTaskID(e.target.value)}
                        />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: 'center',padding:'10px'}}>
                        <div >
                            <Button type="primary" style={{width:'100%'}} onClick={() => query_task_event(taskID)}>
                                查看狀態
                            </Button>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: 'center',padding:'10px'}}>
                        <div >
                            <Button type="primary" style={{width:'100%'}} onClick={() => download_compare_event(taskID)}>
                                下載影片
                            </Button>
                        </div>
                    </Col>
                </Row>
  
            </div>
          </Col>
        </Row>

        <Row justify="center" align="middle" style={{padding:'10px'}} >
            <Col span={24} style={{ textAlign: 'center' }}>
                <div className='rcorners'>
                    
                    
                    <Row  justify="center" align="middle"  >
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: 'center',padding:'10px'}}>
                            <div style={{padding:'10px'}}>
                                <Upload
                                    listType="picture"
                                    maxCount={1}
                                    beforeUpload={handleFilmAFileBeforeload}
                                    onRemove={handleAFilmRemove}
                                    fileList={fileList}
                                >
                                <Button icon={<UploadOutlined />}>上傳A影片</Button>
                                </Upload>
                            </div>
                            <Input     
                                style={{width:'100%'}}
                                className='search_ab_box'
                                placeholder="輸入A影片-起始點時間"
                                value={videoAtime}
                                onChange={e => {
                                    setVideoAtime(e.target.value)
                                    if(playerA) {
                                        playerA.current.seek(e.target.value)
                                        playerA.current.pause()
                                    }   
                                }}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: 'center',padding:'10px'}}>
                            <div style={{padding:"10px"}}>
                                <Upload
                                    listType="picture"
                                    maxCount={1}
                                    beforeUpload={handleFilmBFileBeforeload}
                                    onRemove={handleBFilmRemove}
                                    fileList={fileList}
                                >
                                <Button icon={<UploadOutlined />} >上傳B影片</Button>
                                </Upload>
                            </div>
                  
                            <Input     
                                style={{width:'100%'}}
                                className='search_ab_box'
                                placeholder="輸入B影片-起始點時間"
                                value={videoBtime}
                                onChange={e => {
                                    setVideoBtime(e.target.value)
                                    if(playerB) {
                                        playerB.current.seek(e.target.value)
                                        playerB.current.pause()
                                    }   
                                }}
                            />
                        </Col>
                    </Row>
                    <div style={{padding:"10px"}}>
                        <Button type="primary" block  onClick={() => upload_compare_event()}>
                            確認上傳
                        </Button>
                    </div>
                </div>
            </Col>
            
        </Row>


        <Row  justify="center" align="middle" style={{padding:'10px'}} >
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{ textAlign: 'center'}}>
            <div className='rcorners' >
                <Row  justify="center" align="middle"  >
                    
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: 'center',padding:'20px'}}>
                        <div >
                        { videoFileA != null && 
                            <Player
                                {...({ className: 'scaled-video-player' } as any)}
                                autoPlay={true}
                                ref={playerA}
                                playsInline 
                                poster="/assets/poster.png"
                                
                                src={videoAurl}
                            />}
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: 'center',padding:'20px'}}>
                        <div >
                        { videoFileB != null && 
                            <Player
                                autoPlay={true}
                                ref={playerB}
                                playsInline 
                                poster="/assets/poster.png"
                                src={videoBurl}
                            />}
                        </div>
                    </Col>
                </Row>
  
            </div>
          </Col>
        </Row>
      

        </Layout>
    )
}

export default Member_AB_Layout;


//暫時排除方式
/*
(1). ps -aux | grep pose.py 看pid
(2). kill -9 剛剛的pid
(3). cd /home/nigen8858/pose
(4). nohup python3 pose.py &

2221
*/
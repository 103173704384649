import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Link, useSearchParams } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import { Layout } from 'antd';
import VideoUploadPage from './pages/VideoUploadPage';
import MainTemplate from './pages/mainTemplate';
import { useChangeLocale } from './utils/i18n';
import { useSelector, useDispatch } from 'react-redux';
import { stroeTypes } from './reducers/configureStore';
import { setLocale } from './actions/locale';
import Home from './pages/Home';
import LoginPage from './pages/LoginPage';
import Introduction from './pages/introduction';
import VideoPlayPage from './pages/VideoPlayPage';
import Results from './pages/Results';
import MemberChannel from './pages/MemberChannel';
import  Member_AB_Layout from './pages/MemberAB';
import ConsumerRight from './pages/footer/ConsumerRight';
import TermsOfService from './pages/footer/TermsOfService';
import StorePage from './pages/StorePage';
import ShoppingCartPage from './pages/ShoppingCartPage';
import Footer from './components/Footer';
import ShippingDays from './pages/footer/ShippingDays';
import ReturnPolicy from './pages/footer/ReturnPolicy';
import PrivacyPolicy from './pages/footer/PrivacyPolicy';
import MainHeader from './components/header/MainHeader';
import Service from './pages/Service';
import {StorageKey} from "./core/config";
import * as authAPI from "./api/AuthApi";
import * as Utils from './utils/Utils';
import {setLogin} from "./actions/login";

//訪客頁面
function GuestMainPage() {
    return (
        <Layout style={{ minHeight: "100vh" }}>
            <Sidebar refMain={null}/>
            <div
                className="p-0 m-0"
                style={{
                    height: "100%",
                    width: "100%"
                }}
            >
                <MainHeader />
                <Layout
                    style={{
                        height: "calc(100vh - 70px)",
                        overflowY: "scroll"
                    }}
                >
                    <Home />
                    <Footer/>
                </Layout>
            </div>
        </Layout>
    );
}

//會員頁面
function MemberMainPage() {
  return (
    <MainTemplate
        component={
            <Home />
        }
    />
  );
}

function ServicePage() {
  return (
    <MainTemplate
        component={
            <Service />
        }
    />
  );
}

//商城頁面
function StoreMainPage() {
  return (
    <MainTemplate component={
      <StorePage />
    }/>
  );
}


function ShoppingCartMainPage() {
  return (
    <MainTemplate
    component={
      <ShoppingCartPage />
    }/>
  );
}

//上傳頁面
function MemberUploadPage() {
  return (
    <MainTemplate
    component={
      <VideoUploadPage />
    }/>
  );
}

//個人影片比較
function MemberVideoABPage() {
  return (
    <MainTemplate
    component={
      <Member_AB_Layout />
    }/>
  );
}
function ConsumerMainPage () {
  return(
    <MainTemplate
    component={
      <Layout style={{
        backgroundColor: '#1b1b1b',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          color: '#FFFFFF',
          overflow: 'auto',}}>
        <ConsumerRight />
      </Layout>
    }/>
  )
}
function TOSMainPage () {
  return(
    <MainTemplate
    component={
        <Layout style={{ 
        minHeight: '100vh',
        backgroundColor: '#1b1b1b',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        color: '#FFFFFF',
        overflow: 'auto' }}>
          <TermsOfService />
        </Layout>
    }/>
  )
}
function ShippingDaysMainPage () {
  return(
    <MainTemplate
    component={
      <Layout style={{ 
      minHeight: '100vh',
      backgroundColor: '#1b1b1b',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      color: '#FFFFFF',
      overflow: 'auto' }}>
        <ShippingDays />
      </Layout>
    }
    />
  )
}
function ReturnPolicyMainPage () {
  return(
    <MainTemplate
    component={
      <Layout style={{ 
      minHeight: '100vh',
      backgroundColor: '#1b1b1b',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      color: '#FFFFFF',
      overflow: 'auto' }}>
        <ReturnPolicy />
      </Layout>
    }/>
  )
}
function PrivacyPolicyMainPage () {
  return(
    <MainTemplate
    component={
      <Layout style={{ 
      minHeight: '100vh',
      backgroundColor: '#1b1b1b',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      color: '#FFFFFF',
      overflow: 'auto' }}>
        <PrivacyPolicy />
      </Layout>
    }/>
  )
}
function App() {

  const changeLanguage = useChangeLocale();
  const locale = useSelector((state: stroeTypes) => state.localeReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!locale?.locale) {
        dispatch(setLocale(navigator.language, 'English'))
    }
    locale?.locale && changeLanguage(locale.locale);
}, [locale])

  return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Introduction />} />
                <Route path='login' element={<LoginPage />} />
                <Route path='member_main' element={<MemberMainPage />} />
                <Route path='member_ab' element={<MemberVideoABPage />} />
                <Route path='guest_main' element={<GuestMainPage />} />
                <Route path='payment' element={<StoreMainPage />} />
                <Route path='terms-of-service' element={<TOSMainPage/>} />
                <Route path='consumer-right' element={<ConsumerMainPage />} />
                <Route path='return-policy' element={<ReturnPolicyMainPage/>} />
                <Route path='shipping-days' element={<ShippingDaysMainPage />} />
                <Route path='privacy-policy' element={<PrivacyPolicyMainPage/>} />
                <Route path='shopping-cart' element={<ShoppingCartMainPage/>} />
                <Route path='service' element={<ServicePage />} />
                <Route path='results' element={<Results />} />
                <Route path='member_upload_video' element={<MemberUploadPage />} />
                <Route path='watch' element={<VideoPlayPage />} />
                <Route path='member_channel' element={<MemberChannel />} />
            </Routes>
        </BrowserRouter>
  );
}

export default App;

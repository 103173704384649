import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  urlFacebook,
  urlInstagram,
  urlYoutube,
} from '../core/config';
import { useGetLocaleStr } from '../utils/i18n';
import icon_facebook from '../assets/images/icon_facebook.png'
import icon_youtube from '../assets/images/icon_youtube.png'
import icon_instagram from '../assets/images/icon_instagram.png'
import '../styles/site-footer.css';

function Footer() {
  const navigate = useNavigate();
  const getLocaleStr = useGetLocaleStr();
  const hrStyle = {
    color: 'gray',
    backgroundColor: 'gray',
    height: '1px',
    border: 'none',
    margin: '10px 0',
  };

  return (
    
    <footer className="site-footer">
      <hr style={hrStyle} />
      <div className="container">
        <div className="row">
          <div className="col-sm-6 col-md-4">
            <h6>{getLocaleStr("title-about-us")}</h6>
            <p className="text-justify">
              {getLocaleStr("desc-about-us")}
            </p>
          </div>

          <div className="col-xs-6 col-md-4">
            <h6>{getLocaleStr("title-contact-us")}</h6>
            <ul className="footer-links">
              <li>{getLocaleStr("desc-contact-us-phone")}</li>
              <li>{getLocaleStr("desc-contact-us-fax")}</li>
              <li>{getLocaleStr("desc-contact-us-time")}</li>
              <li>{getLocaleStr("desc-contact-us-email")}</li>
              <li>{getLocaleStr("desc-contact-us-address")}</li>
            </ul>

            <li className="flex flex-row justify-start w-full gap-2">
              <a href={urlInstagram} target="_blank" rel="noreferrer noopenner" className="w-fit">
                <img src={icon_instagram} alt="morld" className="w-[45px]" />
              </a>
              <a href={urlFacebook} target="_blank" rel="noreferrer noopenner" className="w-fit">
                <img src={icon_facebook} alt="morld" width="45" />
              </a>
              <a href={urlYoutube} target="_blank" rel="noreferrer noopenner" className="w-fit">
                <img src={icon_youtube} alt="morld" width="45" />
              </a>
            </li>
          </div>
          <div className="col-xs-6 col-md-4">
            <h6>{getLocaleStr("title-relatedterms")}</h6>
              <Link to="/consumer-right" style={{display: 'block', textDecoration: 'none'}}>{getLocaleStr("title-consumer-rights")}</Link>
              <Link to="/terms-of-service" style={{display: 'block', textDecoration: 'none'}}>{getLocaleStr("title-terms-of-service")}</Link>
              <Link to="/privacy-policy" style={{display: 'block', textDecoration: 'none'}}>{getLocaleStr("title-privacy-policy")}</Link>
              <Link to="/return-policy" style={{display: 'block', textDecoration: 'none'}}>{getLocaleStr("title-return-policy")}</Link>
              <Link to="/shipping-days" style={{display: 'block', textDecoration: 'none'}}>{getLocaleStr("title-shipping-days")}</Link>
              <a href="/term_user.html" style={{display: 'block', textDecoration: 'none'}}>{getLocaleStr("title-morld-vip-terms-of-service")}</a>
              <a href="/term_vip.html" style={{display: 'block', textDecoration: 'none'}}>{getLocaleStr("title-morld-terms-of-service")}</a>
          </div>

          
        </div>
        
      </div>
</footer>
  );
}
  

export default Footer;

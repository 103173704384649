import React from "react";

const ShippingDays = () => {
    return (
        <div style={{padding: '80px', textAlign: 'center'}}>
            <h1>出貨天數</h1>
            <p>工作日7天內出貨 （工作日不包含假日、國訂假日）</p>
        </div>
    );
}
    

export default ShippingDays;

import { SetLocale, localeActionTypes } from "../actions/locale";

const localeReducer = (state = {}, action: localeActionTypes): any => {
  switch (action.type) {
    case SetLocale:
      return ({
        locale: action.payload.locale,
        title: action.payload.title
      });
    default:
      return state;
  }
};
export default localeReducer;
import React, { useEffect, useState } from 'react';
import { Button, Layout, Menu } from 'antd';
import morld_logo from '../../assets/images/morld_logo.png';
import morld_text_img from '../../assets/images/login_morld.png';
import avatar_img from '../../assets/images/avatar.png';
import home from '../../assets/images/home.png';
import HeaderImageBtnItem from './HeaderImageBtnItem';
import { useSelector } from 'react-redux';
import { stroeTypes } from '../../reducers/configureStore';
import { EndPoint } from '../../core/config';

const { Header } = Layout;

function MyHeader() {

    const isLogin = useSelector((state: stroeTypes) => state.isLoggedReducer);
    const [mainEndPoint, setMainEndpoint] = useState<EndPoint>(EndPoint.GUEST_MAIN);

    useEffect(() => {
        if (isLogin) {
            setMainEndpoint(EndPoint.MEMBER_MAIN);
        } else {
            setMainEndpoint(EndPoint.GUEST_MAIN);
        }
    }, [isLogin]);

    return (
      <div
        style={{
          backgroundColor: 'rgba(39, 39, 39, 0.8)',
          borderBottom: 'solid #898989 1px',
          zIndex: 2,
        }}
      >
        <div className='flex justify-between items-center w-full px-2'>

          <a className='flex h-[50px] py-2.5 w-[150px]' style={{ textDecoration: 'none' }} href='/member_main'>
            <img className='' src={morld_text_img} alt='morld' />
          </a>

          <div className='flex justify-center items-center gap-2 w-fit'>
            <a href='/member_main' className="gradient-text text-base flex items-center">Guest</a>
            <a href='/login' className="flex items-center gradient-text text-base">
              Login
              <img className='ml-1 h-[32px] aspect-[1/1] rounded-full' src={avatar_img} alt='morld' />
            </a>
          </div>
        </div>
      </div>
    );
}

export default MyHeader;

import React, { useEffect, useState } from 'react';
import { Layout, message } from 'antd';
import ReactPlayer from 'react-player';

import { VideoInfo } from '../../model/videoInfoModel';
import * as mediaAPI from '../../api/MediaApi';
import pre_home_logo from '../../assets/images/pre_home_morld_logo.png';
import pre_home_logo_font from '../../assets/images/pre_home_morld_logo_font.png';
import app_store_icon from '../../assets/images/app_store.svg';
import pre_home_google_play_logo from '../../assets/images/pre_home_google_play_image.png';

const { Content } = Layout;

function Starter() {
  const [messageApi, contextHolder] = message.useMessage();
  const [hitVideo, setHitVideo] = useState<VideoInfo>();

  const get_random_hit_video = () => {
    mediaAPI
      .getHitVideo()
      .then((data: any) => {
        console.log(data);
        setHitVideo(data);
      })
      .catch((error: any) => {
        messageApi.error(error);
      });
  };

  useEffect(() => {
    get_random_hit_video();
  }, []);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {contextHolder}
     
      <Content
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          minHeight: '100vh',
          width: '100vw',
          zIndex: 1,
          margin: '0',
          padding: '0',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            position: 'absolute',
            backgroundColor: 'rgb(0, 0, 0)',
            height: '100%',
            width: '100%',
            zIndex: 1,
          }}
        >
          {hitVideo && (
            <ReactPlayer
              height='100%'
              width='100%'
              url={hitVideo.video_slug}
              playing
              muted={true}
              loop={true}
            />
          )}
        </div>
        <div
          style={{
            position: 'absolute',
            minHeight: '100vh',
            width: '100vw',
            flex: 1,
            zIndex: 2,
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: "wrap" }}>
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  flex: 0.8,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img style={{ width: '70%' }} src={pre_home_logo} alt='radar_img' />
              </div>
              <div
                style={{
                  flex: 0.5,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <img src={pre_home_google_play_logo} alt='radar_img' />
                </div> */}
              </div>
            </div>
            <a href="https://apps.apple.com/tw/app/morld/id6473081358" className='d-block' style={{width: "20%", minWidth: "300px"}}>
              <img style={{ width: '100%' }} src={app_store_icon} alt='app store' />
            </a>
          </div>
        </div>
      </Content>
    </Layout>
  );
}

export default Starter;

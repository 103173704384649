import { createStore, combineReducers } from 'redux';
import isLoggedReducer from './isLogged';
import localeReducer from './locale';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const rootReducer = combineReducers({
  isLoggedReducer,
  localeReducer
});

const persistConfig = {
  key: 'persist-key',
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer);

const persistor = persistStore(store);

export type stroeTypes = ReturnType<typeof rootReducer>;

export default store;

export { persistor };

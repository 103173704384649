import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Steps,
  message,
  Input,
  Upload,
  Switch,
  Slider,
  Row,
  Col,
  Select
} from 'antd';
import type { SelectProps } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import { Tag } from '../model/videoInfoModel';
import * as mediaAPI from '../api/MediaApi';

import '../styles/VideoUploadPage.css';
import video_upload_img from '../assets/images/video_upload.png';
import add_frame_btn_img from '../assets/images/add_frame_btn.png';
import remove_frame_btn_img from '../assets/images/remove_frame_btn.png';
import type { RcFile, UploadFile } from 'antd/es/upload/interface';

const { TextArea } = Input;
const { Dragger } = Upload;

interface FrameRecord {
  key: any;
  img?: any;
  seconds?: any;
}

function FrameRecordBlocks(props: {
  frameRecords: any;
  setFrameRecords: any;
  delete_frame_record: any;
}) {
  return (
    <>
      {props.frameRecords.map((record: FrameRecord) => (
        <Row key={record.key} className='list_row' gutter={16}>
          <Col className='list_item_row' span={6}>
            <div className='list_img'>
              {record.img && <img style={{ margin: 'auto' }} src={record.img} alt='' />}
            </div>
          </Col>
          <Col className='list_item_row' span={6}>
            <div>{record.seconds}</div>
          </Col>
          <Col className='list_item_row' span={6}>
            <Button type='text'>
              <img
                src={remove_frame_btn_img}
                alt='remove_frame_btn_img'
                onClick={() => {
                  props.delete_frame_record(record.key);
                }}
              />
            </Button>
          </Col>
          <Col className='list_item_row' span={6}></Col>
        </Row>
      ))}
    </>
  );
}

function VideoHandler(videoElement: any, canvasRef: any, setCaptureFrame: any) {
  const [playerState, setPlayerState] = useState({
    triggerGetFrame: 0,
    triggerFirstFrame: '',
    setVideoTime: -1,
    triggerCapture: '',
  });
  // set current video to target time
  const setFrame = (time: number) => {
    setPlayerState({
      ...playerState,
      triggerGetFrame: time,
    });
  };

  useEffect(() => {
    capture(playerState.triggerGetFrame);
  }, [playerState.triggerGetFrame, videoElement]);

  const capture = (time: number) => {
    setPlayerState({
      ...playerState,
      setVideoTime: time,
    });
  };

  useEffect(() => {
    videoElement.current.currentTime = playerState.setVideoTime;
  }, [playerState.setVideoTime, videoElement]);

  const getFirstFrame = () => {
    setPlayerState({
      ...playerState,
      triggerFirstFrame: (Math.random() + 1).toString(36).substring(7),
    });
  };

  useEffect(() => {
    capture(1);
  }, [playerState.triggerFirstFrame, videoElement]);

  // listen to video onTimeUpdate callback
  const triggerCapture = () => {
    setPlayerState({
      ...playerState,
      triggerCapture: (Math.random() + 1).toString(36).substring(7),
    });
  };

  useEffect(() => {
    canvasRef.current.width = videoElement.current.videoWidth;
    canvasRef.current.height = videoElement.current.videoHeight;
    canvasRef.current
      .getContext('2d')
      .drawImage(videoElement.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
    const image = canvasRef.current.toDataURL();

    setCaptureFrame(image);
  }, [playerState.triggerCapture, videoElement]);

  return {
    getFirstFrame,
    setFrame,
    triggerCapture,
  };
}

function VideoEditFirstPage(props: {
  fileImageList: any;
  setFileImageList: any;
  fileTxtList: any;
  setFileTxtList: any;
  videoTitle: any;
  setVideoTitle: any;
  videoTitleStatus: any;
  videoDesc: any;
  setVideoDesc: any;
  messageApi: any;
  tagList: any;
  setSelectedTags: any;
}) {
  /* eslint-disable */
  const [loading, setLoading] = useState(false);
  /* eslint-enable */

  const [tagOptions, setTagOptions] = useState<SelectProps['options']>([]);

  //驗證檔案類型
  const validateFileType = ({ type, name }: UploadFile, allowedTypes?: string) => {
    if (!allowedTypes) {
      return true;
    }

    if (type) {
      return allowedTypes.includes(type);
    }
  };

  //處理文字檔上傳前檢查事件
  const handleTxtFileBeforeload = (file: RcFile) => {
    //檢查是否為txt
    const isAllowedType = validateFileType(file, 'text/plain');
    //若不是txt
    if (!isAllowedType) {
      message.error(`${file.name} 這不是文字檔`);
      return Upload.LIST_IGNORE;
    }
    return false;
  };

  useEffect(() => {
    if (props.tagList) {
      const options: SelectProps['options'] = [];
      for (let i = 0; i < props.tagList.length; i++) {
        options.push({
          label: props.tagList[i].name,
          value: props.tagList[i].id,
        });
      }
      setTagOptions(options);
    } else {
      setTagOptions([]);
    }
  }, [props.tagList]);

  const new_uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>檔案上傳</div>
    </div>
  );
  //標籤狀態改變處理事件
  const handleChange = (_: any, option: any) => {
    const selected_ids: number[] = option.map((obj: any) => obj.value);
    const selected_tags = [...props.tagList].filter((tag: Tag) => selected_ids.includes(tag.id));
    props.setSelectedTags(selected_tags);
  };
  //縮圖狀態改變處理事件
  const handleImageChange = (file: RcFile) => {
    //檢查是否為image
    const isAllowedType = validateFileType(file, 'image/jpeg');
    //若不是image
    if (!isAllowedType) {
      message.error(`${file.name} 這不是圖片檔`);
      return Upload.LIST_IGNORE;
    }
    return false;
  };

  return (
    <>
      <div className='input_block'>
        <label>標題(必填)</label>
        <Input
          status={props.videoTitleStatus}
          value={props.videoTitle}
          onChange={(e) => {
            props.setVideoTitle(e.target.value);
          }}
        />
      </div>

      <div className='input_block' style={{ marginTop: '10px' }}>
        <label>描述(選填)</label>
        <TextArea
          autoSize={{ minRows: 4, maxRows: 4 }}
          value={props.videoDesc}
          onChange={(e) => {
            props.setVideoDesc(e.target.value);
          }}
        />
      </div>

      <div className='input_block' style={{ marginTop: '10px' }}>
        <label>選擇標籤</label>
        <Select
          mode='multiple'
          allowClear
          style={{ width: '100%' }}
          placeholder=''
          defaultValue={[]}
          onChange={handleChange}
          options={tagOptions}
          size={'large'}
        />
      </div>

      <div className='flex-container' style={{ marginTop: '10px' }}>
        <div style={{ marginTop: '10px' }}>
          <label>縮圖</label>
          <Upload
            listType='picture-card'
            maxCount={1} //最多一個檔案
            onChange={(e: any) => {
              props.setFileImageList(e.fileList);
            }}
            fileList={props.fileImageList}
            beforeUpload={handleImageChange}
          >
            {props.fileImageList.length > 0 ? null : new_uploadButton}
          </Upload>
        </div>
        <div style={{ marginTop: '10px' }}>
          <label>文字檔</label>
          <Upload
            listType='picture-card'
            showUploadList={true}
            maxCount={1} //最多一個檔案
            onChange={(e: any) => {
              props.setFileTxtList(e.fileList);
            }}
            beforeUpload={handleTxtFileBeforeload} //上傳前處理事件
            fileList={props.fileTxtList}
          >
            {props.fileTxtList.length > 0 ? null : new_uploadButton}
          </Upload>
        </div>
      </div>
    </>
  );
}

function VideoEditSecondPage(props: {
  videoFile: any;
  captureFrame: any;
  getFirstFrame: any;
  setFrame: any;
  videoRef: any;
  frameRecords: FrameRecord[];
  setFrameRecords: any;
  delete_frame_record: any;
  inputValue: any;
  setInputValue: any;
}) {
  const add_frame_record = () => {
    const tmp: number =
      Math.round(props.videoRef.current.duration * (props.inputValue / 10) * 100) / 100;
    const new_frame_record: FrameRecord = {
      key: (Math.random() + 1).toString(36).substring(7),
      img: props.captureFrame,
      seconds: float_to_time_string(tmp),
    };
    props.setFrameRecords((frameRecords: FrameRecord[]) => [...frameRecords, new_frame_record]);
  };

  const onChange = (newValue: number) => {
    props.setInputValue(newValue);
  };

  useEffect(() => {
    const tmp: number =
      Math.round(props.videoRef.current.duration * (props.inputValue / 100) * 100) / 100;
    props.setFrame(tmp);
  }, [props.inputValue]);

  const formatter: any = (value: number) => {
    const tmp: number = Math.round(props.videoRef.current.duration * (value / 100) * 100) / 100;
    return float_to_time_string(tmp);
  };

  const float_to_time_string = (time: number) => {
    const hours = Math.floor(time / 3600);
    time = time - hours * 3600;
    const minutes = Math.floor(time / 60);
    time = time - minutes * 60;
    const seconds = Math.floor(time);

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  };

  return (
    <>
      <div>
        <div className='second_page_top_container'>
          <div className='second_page_top_block_left_panel' style={{ backgroundColor: '#000000' }}>
            {props.captureFrame && (
              <img style={{ margin: 'auto' }} height='199' src={props.captureFrame} alt='' />
            )}
          </div>
          <div className='second_page_top_block_right_panel'>
            <Button
              className='add_frame_btn'
              type='text'
              onClick={() => {
                add_frame_record();
              }}
            >
              <p>關鍵畫面資訊</p>
              <img src={add_frame_btn_img} alt='add_frame_btn_img' />
            </Button>
          </div>
        </div>
        <Slider
          className='second_page_slider'
          onChange={onChange}
          value={props.inputValue}
          tooltip={{ formatter }}
        />
        <div className='second_page_bot_container'>
          <Row className='list_title' gutter={16}>
            <Col className='list_title_row' span={6}>
              <div>分點用圖</div>
            </Col>
            <Col className='list_title_row' span={6}>
              <div>時間</div>
            </Col>
            <Col className='list_title_row' span={6}>
              <div>模式</div>
            </Col>
            <Col className='list_title_row' span={6}></Col>
          </Row>
          <div className='second_page_bot_block_records'>
            <FrameRecordBlocks
              frameRecords={props.frameRecords}
              setFrameRecords={props.setFrameRecords}
              delete_frame_record={props.delete_frame_record}
            />
          </div>
        </div>
      </div>
    </>
  );
}

function VideoEditThirdPage(props: {
  isPublic: boolean;
  setIsPublic: any;
  isForSell: boolean;
  setIsForSell: any;
  videoPrice: number;
  setVideoPrice: any;
}) {
  return (
    <>
      <div className='video_edit_third_page_block'>
        <label>是否公開瀏覽</label>
        <Switch
          checked={props.isPublic}
          onChange={(checked) => {
            props.setIsPublic(checked);
          }}
        />
      </div>
      <div className='video_edit_third_page_block'>
        <label>是否販賣</label>
        <Switch
          checked={props.isForSell}
          onChange={(checked) => {
            props.setIsForSell(checked);
          }}
        />
      </div>
      <div className='video_edit_third_page_block'>
        <label>價格</label>
        <Input
          style={{ width: '200px' }}
          value={props.videoPrice}
          onChange={(e) => {
            props.setVideoPrice(e.target.value);
          }}
        />
      </div>
    </>
  );
}

function VideoUploadPage() {
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  const [videoFile, setVideoFile] = useState<any>();

  const [current, setCurrent] = useState(0);
  const [fileImageList, setFileImageList] = useState<UploadFile[]>([]);

  const [fileTxtList, setFileTxtList] = useState<UploadFile[]>([]);

  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [captureFrame, setCaptureFrame] = useState<any>();
  const { getFirstFrame, setFrame, triggerCapture } = VideoHandler(
    videoRef,
    canvasRef,
    setCaptureFrame,
  );
  const [videoSrc, setVideoSrc] = useState<any>();

  const [videoTitle, setVideoTitle] = useState<string>('');
  const [videoTitleStatus, setVideoTitleStatus] = useState<string>('');
  const [videoDesc, setVideoDesc] = useState<string>('');

  const [isPublic, setIsPublic] = useState<boolean>(true);
  const [isForSell, setIsForSell] = useState<boolean>(true);
  const [videoPrice, setVideoPrice] = useState<number>(0);

  const [frameRecords, setFrameRecords] = useState<FrameRecord[]>([]);

  const [inputValue, setInputValue] = useState(0);

  const [disableButton, setDisableButton] = useState<boolean>(false);

  const [tagList, setTagList] = useState<Tag[]>([]);
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);

  useEffect(() => {
    get_tag_list();
  }, []);

  const get_tag_list = () => {
    mediaAPI
      .getTags()
      .then((tags: any) => {
        setTagList(tags);
      })
      .catch((error: any) => {
        messageApi.error(error);
      });
  };

  const delete_frame_record = (remove_key: any) => {
    const newList = frameRecords.filter((record: FrameRecord) => {
      return record.key !== remove_key;
    });
    setFrameRecords(newList);
  };

  useEffect(() => {
    if (videoFile) {
      const url = URL.createObjectURL(videoFile);
      setVideoSrc(url);
    }
  }, [videoFile]);

  const next = () => {
    if (current == 0) {
      if (videoTitle == '') {
        setVideoTitleStatus('error');
        messageApi.error('影片標題為必填欄位');
        return;
      } else {
        setVideoTitleStatus('');
      }
    }
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const steps = [
    {
      title: '影片資料',
      content: (
        <VideoEditFirstPage
          fileImageList={fileImageList}
          setFileImageList={setFileImageList}
          fileTxtList={fileTxtList}
          setFileTxtList={setFileTxtList}
          videoTitle={videoTitle}
          setVideoTitle={setVideoTitle}
          videoTitleStatus={videoTitleStatus}
          videoDesc={videoDesc}
          setVideoDesc={setVideoDesc}
          messageApi={messageApi}
          tagList={tagList}
          setSelectedTags={setSelectedTags}
        />
      ),
    },
    {
      title: '分點用圖',
      content: (
        <VideoEditSecondPage
          videoFile={videoFile}
          captureFrame={captureFrame}
          getFirstFrame={getFirstFrame}
          setFrame={setFrame}
          videoRef={videoRef}
          frameRecords={frameRecords}
          setFrameRecords={setFrameRecords}
          delete_frame_record={delete_frame_record}
          inputValue={inputValue}
          setInputValue={setInputValue}
        />
      ),
    },
    {
      title: '影片設定',
      content: (
        <VideoEditThirdPage
          isPublic={isPublic}
          setIsPublic={setIsPublic}
          isForSell={isForSell}
          setIsForSell={setIsForSell}
          videoPrice={videoPrice}
          setVideoPrice={setVideoPrice}
        />
      ),
    },
  ];

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const onTimeUpdate = () => {
    triggerCapture();
  };
  // test - create txt
  // function exportUserInfo(userInfo: UserInfo) {
  //   const fileData = JSON.stringify(userInfo);
  //   const blob = new Blob([fileData], { type: "text/plain" });
  //   const url = URL.createObjectURL(blob);
  //   const link = document.createElement("a");
  //   link.download = "user-info.json";
  //   link.href = url;
  //   link.click();
  // }

  const loading_msg_key = 'loading_msg_key';

  const upload_video = () => {
    // console.log('video title:' + videoTitle);
    // console.log('video desc:' + videoDesc);
    // console.log('video src:' + videoFile);
    // console.log('video 是否公開:' + isPublic);
    // console.log('video 是否販賣:' + isForSell);
    // console.log('video 價錢:' + videoPrice);
    // console.log('關鍵圖:' + frameRecords);
    // console.log('token:' + userInfo.token);
    // const newList = frameRecords.filter((record: FrameRecord) => {
    //   console.log("關鍵圖:"+record.key)
    //   console.log("關鍵圖:"+record.seconds)
    //   console.log("關鍵圖:"+record.img)
    // })

    setDisableButton(true);
    messageApi.open({
      key: loading_msg_key,
      type: 'loading',
      content: '上傳影片中...',
      duration: 0,
    });

    const form = new FormData();
    form.append('title', videoTitle);
    form.append('description', videoDesc);
    form.append('film', videoFile);
    if (fileImageList.length == 1 && fileImageList[0].originFileObj) {
      form.append('present_img', fileImageList[0].originFileObj);
    }
    if (fileTxtList.length == 1 && fileTxtList[0].originFileObj) {
      form.append('film_txt', fileTxtList[0].originFileObj);
    }
    form.append('film_type_id', '1');
    //是否要公開
    if (isPublic) {
      form.append('publish', '1');
    } else {
      form.append('publish', '0');
    }
    //是否要販賣
    if (isForSell) {
      form.append('sell', '1');
    } else {
      form.append('sell', '0');
    }
    form.append('split_image', JSON.stringify(frameRecords));
    form.append('film_tags', JSON.stringify(selectedTags));
    console.log('TODO upload selected tags: ', JSON.stringify(selectedTags));

    mediaAPI
      .uploadVideo(form)
      .then(() => {
        messageApi.open({
          key: loading_msg_key,
          type: 'success',
          content: '上傳成功',
          duration: 2,
        });

        setTimeout(() => {
          navigate('/member_channel');
        }, 2000);
      })
      .catch((error: any) => {
        console.log('uploadVideo error: ', error);
        messageApi.destroy(loading_msg_key);
        messageApi.error('標題已被使用');
        setDisableButton(false);
      });
  };

  const check_video_format = (video_type: string) => {
    if (video_type === 'video/mp4') {
      return true;
    }
    return false;
  };

  return (
    <>
      {contextHolder}
      <div className='video_upload_outter'>
        <div className='video_upload_panel'>
          {videoFile == null && (
            <Dragger
              beforeUpload={(file: RcFile) => {
                if (check_video_format(file.type)) {
                  messageApi.success(`成功載入影片 ${file.name}`);
                  setVideoFile(file);
                } else {
                  messageApi.error(`${file.type} 不是一個合法的影片格式`);
                }
                return false;
              }}
              showUploadList={false}
            >
              <p className='ant-upload-drag-icon'>
                <img src={video_upload_img} alt='video_upload_img' />
              </p>
              <p className='ant-upload-text'>上傳影片</p>
            </Dragger>
          )}
          {videoFile != null && (
            <div className='video_edit_panel'>
              <Steps
                progressDot
                className='video_edit_steps'
                labelPlacement='vertical'
                current={current}
                items={items}
              />
              <div className='video_edit_body_container'>{steps[current].content}</div>
              <div style={{ marginTop: 15, display: 'flex' }}>
                {current > 0 && (
                  <Button disabled={disableButton} onClick={() => prev()}>
                    上一步
                  </Button>
                )}
                {current < steps.length - 1 && (
                  <Button
                    disabled={disableButton}
                    style={{ marginLeft: 'auto' }}
                    type='primary'
                    onClick={() => next()}
                  >
                    下一步
                  </Button>
                )}
                {current === steps.length - 1 && (
                  <Button
                    disabled={disableButton}
                    style={{ marginLeft: 'auto' }}
                    type='primary'
                    onClick={() => {
                      upload_video();
                    }}
                  >
                    完成
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <video
        hidden
        ref={videoRef}
        src={videoSrc}
        width='320'
        height='240'
        controls
        onTimeUpdate={onTimeUpdate}
      />
      <canvas hidden ref={canvasRef}></canvas>
    </>
  );
}

export default VideoUploadPage;

// Classes for data storage

class UserInfo {
  email = '';
  name = '';
  avatar_image_slug = '';
  follow_count = 0;
  id: number;

  constructor(data: any) {
    this.id = data.id;
    if (data.email) this.email = data.email;
    if (data.name) this.name = data.name;
    if (data.avatar_image_slug) this.avatar_image_slug = data.avatar_image_slug;
    if (data.follow_count) this.follow_count = data.follow_count;
  }
}

class DetailInfo {
  publish: boolean;
  sell: boolean;
  film_review_status = '';
  process_status = '';

  constructor(data: any) {
    this.publish = data.publish;
    this.sell = data.sell;
    if (data.film_review_status) this.film_review_status = data.film_review_status;
    if (data.process_status) this.process_status = data.process_status;
  }
}

class KeyImageInfo {
  key_time = '';
  key_image_slug = '';

  constructor(data: any) {
    if (data.key_time) this.key_time = data.key_time;
    if (data.key_image_slug) this.key_image_slug = data.key_image_slug;
  }
}

class VideoInfo {
  id = '';
  uuid = '';
  title = '';
  description = '';
  price = 0;
  amount = 1;
  total = 0;
  present_image_slug = '';
  video_slug = '';
  video_hls_slug = '';
  txt_slug = '';
  video_time = '';
  view_count = 0;
  like_count = 0;
  follow_count = 0;
  user_info: UserInfo;
  detail_info: DetailInfo;
  key_image_info: KeyImageInfo[] = [];
  update_time = '';
  image_type = '';
  yt1 = '';
  yt2 = '';

  constructor(data: any) {
    if (data.id) this.id = data.id;
    if (data.uuid) this.uuid = data.uuid;
    if (data.title) this.title = data.title;
    if (data.description) this.description = data.description;
    if (data.present_image_slug) this.present_image_slug = data.present_image_slug;
    if (data.video_slug) this.video_slug = data.video_slug;
    if (data.video_hls_slug) this.video_hls_slug = data.video_hls_slug;
    if (data.txt_slug) this.txt_slug = data.txt_slug;
    if (data.video_time) this.video_time = data.video_time;
    if (data.view_count) this.view_count = data.view_count;
    if (data.like_count) this.like_count = data.like_count;
    if (data.follow_count) this.follow_count = data.follow_count;
    this.user_info = new UserInfo(data.user_info);
    this.detail_info = new DetailInfo(data.detail_info);
    if (data.key_image_info.length > 0) {
      for (let i = 0; i < data.key_image_info.length; i++) {
        this.key_image_info.push(new KeyImageInfo(data.key_image_info[i]));
      }
    }
    if (data.yt1) this.yt1 = data.yt1;
    if (data.yt2) this.yt2 = data.yt2;
    if (data.image_type) this.image_type = data.image_type;
    if (data.update_time) this.update_time = data.update_time;
    if (data.price) this.price = data.price;
  }
}

class Tag {
  id: number;
  name: string;
  selected = false;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }
}

class UploadVideoInfo {
  title = '';
  description = '';
  film: any;
  present_img: any;
  film_txt = '';
  film_type_id = '1';
  publish = '0';
  sell = '0';
  split_image = [];
}

//單個留言
class VideoComment {
  id: number;
  nickname: string;
  body: string;
  created_at: string;

  constructor(data: any) {
    this.id = data.id;
    this.nickname = data.nickname;
    this.body = data.body;
    this.created_at = data.created_at;
  }
}

//喜歡/取消喜歡 - 某影片的事件回應
class VideoLikeResponse {
  like_by_id: string;
  total_count: number;
  constructor(data: any) {
    this.like_by_id = data.like_by_id;
    this.total_count = data.total_count;
  }
}

//關注/取消關注 - 關注某使用者的事件回應
class UserFollowResponse {
  follow_by_id: string;
  total_count: string;
  constructor(data: any) {
    this.follow_by_id = data.follow_by_id;
    this.total_count = data.total_count;
  }
}

//檢查影片狀態 - 是否已關注或喜歡
class CheckVideoStatusResponse {
  like_checked: boolean;
  follow_checked: boolean;
  constructor(data: any) {
    this.like_checked = data.like_checked;
    this.follow_checked = data.follow_checked;
  }
}

class ViewCountResponse {
  view_count: number;
  constructor(data: any) {
    this.view_count = data.view_count;
  }
}

export {
  VideoInfo,
  Tag,
  UploadVideoInfo,
  VideoComment,
  VideoLikeResponse,
  UserFollowResponse,
  CheckVideoStatusResponse,
  ViewCountResponse,
};

import { StorageKey } from "../core/config";
import { VideoInfo } from "../model/videoInfoModel";
import * as authAPI from "../api/AuthApi";
import {setLogin} from "../actions/login";
import { useSelector, useDispatch } from 'react-redux';

export const loginWithToken = (dispatch, token: string) => {
    if(token == null) return;
    if(token == '') return;
    localStorage.setItem(StorageKey.TOKEN_KEY, token);
    authAPI.loginWithToken().then((data: any) => {
        if (data.error === '') {
            dispatch(setLogin(true, data.data.user_info.email, data.data.token, data.data));
            //alert(1);
        } else {
            alert(2)
        }
    })
        .catch(() => {
            alert(3)
        });
}
import React, { useState, useEffect, useRef } from 'react';
import MainHeader from "../components/header/MainHeader";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import { Layout } from "antd";
import _ from "lodash";
import {useSearchParams} from "react-router-dom";
import * as Utils from "../utils/Utils";

const MainTemplate = (props: { component: any }) => {

    const refMain = useRef(null);
    const [mainHeight, setMainHeight] = useState(1);
    const observedDiv = useRef(null);

    useEffect(() => {
        if (!observedDiv.current) {
            return;
        }

        const resizeObserver = new ResizeObserver(() => {
            if (observedDiv.current.offsetHeight !== mainHeight) {
                setMainHeight(observedDiv.current.offsetHeight);
            }
        });

        resizeObserver.observe(observedDiv.current);
        return function cleanup() {
            resizeObserver.disconnect();
        }
    }, [observedDiv.current])

    return (
        <Layout className='w-full' style={{ height: mainHeight + 'px' }} >
            <Sidebar/>
            <div ref={observedDiv} className="p-0 m-0 h-fit w-full ">
                <MainHeader />
                <Layout>
                    {props.component}
                </Layout>
                <Footer />
            </div>
        </Layout>
    )

}
export default MainTemplate;
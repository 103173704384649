import { StorageKey } from "../core/config";
import { VideoInfo } from "../model/videoInfoModel";

export const addToShoppingCart = (product: VideoInfo) => {
    const productList: Array<VideoInfo> =
    JSON.parse(localStorage.getItem(StorageKey.SHOPPING_CART) || "[]") || [];
    productList.push(product);
    const productListStr = JSON.stringify(productList);
    localStorage.setItem(StorageKey.SHOPPING_CART, productListStr);
}

export const deleteFromShoppingCart = (id: string) => {
    let productList: Array<VideoInfo> =
        JSON.parse(localStorage.getItem(StorageKey.SHOPPING_CART) || "[]") || [];
    productList = productList.filter((product) => {
        return product.id !== id;
    })
    const productListStr = JSON.stringify(productList);
    localStorage.setItem("shoppingCart", productListStr);
}

import * as restAPI from '../utils/axiosManager';
import {API_HOST} from '../utils/axiosManager';
import { Tag, VideoInfo } from '../model/videoInfoModel';
import axios from 'axios';
import { StorageKey } from '../core/config';

const getTags = () => {
  return new Promise((resolve, reject) => {
    restAPI
      .request('get', 'api/film_tag', {})
      .then((res: any) => {
        const data = res.data;
        const tags: Tag[] = [];
        for (const tag of data.data) {
          tags.push(new Tag(tag.id, tag.name));
        }
        resolve(tags);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

const getVideos = (page = 1, tag_id = -1, search_query = '', id = '') => {
  return new Promise((resolve, reject) => {
    const url = 'api/film';

    const params: any = {};

    params['search_type'] = 'blurred';

    if (page != 1) {
      params['page'] = page;
    }
    if (tag_id != -1) {
      params['tags'] = tag_id;
    }
    if (search_query != '') {
      params['title'] = search_query;
    }
    if (id != '') {
      params['uuid'] = id;
    }

    restAPI
      .request('get', url, params)
      .then((res: any) => {
        const data = res.data;
        if (data.error !== '') {
          reject(data.error);
        }
        resolve(data);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

const getVideosBySearchQuery = (search_query: string) => {
  return new Promise((resolve, reject) => {
    let url = 'api/film?search_type=blurred';
    if (search_query) {
      url = `api/film?search_type=blurred&title=${search_query}`;
    }
    restAPI
      .request('get', url, {})
      .then((res: any) => {
        const data = res.data;
        if (data.error !== '') {
          reject(data.error);
        }

        const video_list: VideoInfo[] = [];
        for (let i = 0; i < data.data.length; i++) {
          video_list.push(new VideoInfo(data.data[i]));
        }

        resolve(video_list);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

const getVideosBySearchQueryAndTag = (search_query: string, tag_id: number) => {
  return new Promise((resolve, reject) => {
    let url = 'api/film??search_type=blurred';
    if (search_query) {
      url = `api/film??search_type=blurred&title=${search_query}&tags=${tag_id}`;
    }
    restAPI
      .request('get', url, {})
      .then((res: any) => {
        const data = res.data;
        if (data.error !== '') {
          reject(data.error);
        }

        const video_list: VideoInfo[] = [];
        for (let i = 0; i < data.data.length; i++) {
          video_list.push(new VideoInfo(data.data[i]));
        }

        resolve(video_list);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

const getVideosById = async (id: string) => {
  return new Promise((resolve, reject) => {
    let url = 'api/film?search_type=blurred';
    if (id) {
      url = `api/film?search_type=appoint&uuid=${id}`;
    }
    restAPI
    .request('get', url, {})
    .then((res: any) => {
      const data = res.data;
      if (data.error !== '') {
        reject(data.error);
      }

      if (data.data == null) {
        reject(`找不到 uuidss=${id} 的影片`);
      }
      const video = new VideoInfo(data.data);
      resolve(video);
    })
    .catch((err: any) => {
      reject(err);
    });
    url = `api/film/watch?search_type=appoint&uuid=${id}`;
    restAPI
    .request('get', url, {})
    .then((response: any) => {
        console.log(response);
    })
    .catch((error: Error) => {
      return;
    })
  });
};

const getHitVideo = () => {
  return new Promise((resolve, reject) => {
    restAPI
      .request('get', 'api/hot_film?type=random&threshold_count=-1', {})
      .then((res: any) => {
        const data = res.data;
        if (data.error !== '') {
          reject(data.error);
        }
        const hit_video_info = new VideoInfo(data.data);
        console.log(['hit_video_info',hit_video_info])
        resolve(hit_video_info);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

const uploadVideo = (data: any) => {
  return new Promise((resolve, reject) => {
    restAPI
      .request('post', 'api/film', data)
      .then((res: any) => {
        if (res.data.error) {
          reject(res.data.error);
        }
        resolve(res.data);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

const deleteVideo = (data: any) => {
  return new Promise((resolve, reject) => {
    restAPI
      .request('delete', 'api/film', data)
      .then((res: any) => {
        if (res.data.error) {
          reject(res.data.error);
        }
        //console.log(res.data);
        resolve(res.data);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

const getPersonalVideos = (page: number) => {
  return new Promise((resolve, reject) => {
    let url = 'api/film_p';
    if (page != 1) {
      url = `${url}?page=${page}`;
    }
    restAPI
      .request('get', url, {})
      .then((res: any) => {
        const data = res.data;
        if (data.error && data.error !== '') {
          reject(data.error);
        }
        resolve(data);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export {
  getTags,
  getVideos,
  getHitVideo,
  getVideosBySearchQuery,
  uploadVideo,
  deleteVideo,
  getPersonalVideos,
  getVideosById,
  getVideosBySearchQueryAndTag,
};

import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Layout } from 'antd';
import Button from 'react-bootstrap/Button';
import { VideoInfo } from '../model/videoInfoModel';
import {deleteFromShoppingCart } from '../utils/shoppingCart';
import { stroeTypes } from '../reducers/configureStore';
//css
import '../assets/css/LoginPage.css';
import '../assets/css/main_home.css';
import '../styles/Home.css';
import { StorageKey } from '../core/config';
import Select from 'react-select';
import _ from "lodash";
import remove_frame_btn_img from '../assets/images/remove_frame_btn.png';
const { Content } = Layout;
import Swal from 'sweetalert2'
import BuyInfo from '../components/BuyInfo';

function ShoppingCartPage() {

    const [data, setData] = useState({'country':'TW'});
    const [showBuyInfo, setShowBuyInfo] = useState<boolean>(false);
    const isLogin = useSelector((state: stroeTypes) => state.isLoggedReducer);
    const locale = useSelector((state: stroeTypes) => state.localeReducer);
    const refPay = useRef(null);
    const [orderList, setOrderList] = useState<VideoInfo[]>([]);
    const [optionsAmount, setOptionsAmount] = useState([]);
    const getOrderList = () => {
        const productList: Array<VideoInfo> =
            JSON.parse(localStorage.getItem(StorageKey.SHOPPING_CART) || "[]") || [];
        setOrderList(productList);
    }

    useEffect(() => {
        getOrderList();
        if (optionsAmount.length == 0) {
            const dd = [];
            for (let i = 1; i <= 20; i++) {
                dd.push({ value: i, label: i });
            }
            setOptionsAmount(dd)
        }
    }, []);

    useEffect(() => {
        console.log(['locale',locale])
        setData(prevState => ({...prevState, lang: (locale.locale)}));
    }, [locale]);

    useEffect(() => {
        localStorage.setItem(StorageKey.SHOPPING_CART, JSON.stringify(orderList));
    }, [orderList]);

    const confirmBuy = () => {
        const total = orderList.reduce((prev, product)=> {
            return prev+(product.price * _.get(product, 'amount', 1));
        }, 0);
        const fake_total = total * 33;
        const real_total = total * 32;
        const tax = fake_total - real_total;
        setData(prevState => ({
            ...prevState,
            finalPay: (fake_total),
        }));
        const msg = `購買後七天內且都未使用課程即可100%退款<br/>購買後8-14天且未使用課程．將退款30%<br/>有使用過課程將不予退款。<br/><br/>金額:TWD${real_total}<br/>稅金:TWD${tax}<br/>付款金額:TWD${tax+real_total}`;
        Swal.fire({
            title: "提醒",
            html: msg,
            showCancelButton: true,
            confirmButtonText: "同意",
            customClass:{
                confirmButton: 'bg-[#ffffff] text-[#1b1b1b] rounded-md text-xl px-3 py-1',
                cancelButton: 'bg-[#ffffff] text-[#1b1b1b] rounded-md text-xl px-3 py-1',
            },
            background:'#1b1b1b', 
            color:"#ffffff",
          }).then((result) => {
            if (result.isConfirmed) {
              setShowBuyInfo(true)
            }
          });
    };

    return (
    <Content
        style={{
        backgroundColor: '#1b1b1b',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        minHeight: "40vh"
        }}
    >

        <BuyInfo show={showBuyInfo} onDismiss={() => { setShowBuyInfo(false) }} onDone={async (res) => {
            const items = [];
            orderList.map((item,index)=>{
                items.push({
                    amount:item.amount,
                    price:item.price,
                    id:item.uuid,
                    title:item.title
                });
            })
            await setData(prevState => ({
                ...prevState,
                items:JSON.stringify(items),
                carriers: (res.carriers),
                tax_id: (res.tax_id),
                invoice_type: (res.invoice_type),
                name: (res.name),
                nid: res.nid,
                country: res.country
            }));
            refPay.current.submit()
        }} />

        <form ref={refPay} className="hidden" action="https://api.morld.com.tw/checkout/purchase" method="post">
            <input type="text" name="email" value={isLogin.mail} />
            <input type="text" name="finalPay" value={_.get(data, 'finalPay', '')} />
            <input type="text" name="lang" value={_.get(data, 'lang', '')} />
            <input type="text" name="items" value={_.get(data, 'items', '')} />

            <input type="text" name="tax_id" value={_.get(data, 'tax_id', '')} />
            <input type="text" name="nid" value={_.get(data, 'nid', '')} />
            <input type="text" name="invoice_type" value={_.get(data, 'invoice_type', '')} />
            <input type="text" name="carriers" value={_.get(data, 'carriers', '')} />
            <input type="text" name="country" value={_.get(data, 'country', '')} />
        </form>

        <div style={{ paddingTop: '20px', height: '100%', overflow: 'auto' }}>
            <div style={{
                maxWidth: '1200px',
                margin: 'auto',
                padding: "20px",
                color: "#ffffff",
                paddingTop: "100px" }}
            >
                <h1 className="mb-4 text-xl2">購物車總計</h1>
                { orderList.length === 0 ?
                    <div className='mb-4' style={{
                        fontSize: "2rem",
                        margin: "20px",
                        textAlign: "center"
                    }}>購物車目前沒有商品哦</div> :
                <>

                <table className='w-full'>
                    <thead>
                        <th className='text-center'>商品</th>
                        <th className='text-center'>價格</th>
                        <th className='text-center'>數量</th>
                        <th className='text-center'>小計</th>
                        <th className='text-center'>刪除</th>
                    </thead>
                    <tbody>
                        {orderList.map((product, index) =>
                            <tr key={product.id} className='pb-4'>
                                <td className='w-1/3 text-center'>
                                    <img className='w-full' src={product.present_image_slug}
                                        style={{
                                            objectFit: 'contain'
                                        }}
                                    />
                                    {product.title}
                                </td>
                                <td className='text-center'>USD ${product.price}</td>
                                <td className='text-center'>
                                    <select onChange={(e) => {
                                        const d = _.cloneDeep(orderList);
                                        d[index].amount = parseInt(e.target.value);
                                        console.log(d);
                                        setOrderList(d);
                                    }}>
                                        {optionsAmount.map(item =>
                                            <option key={item.value} value={item.value} selected={_.get(product.amount, 0) == item.value}>{item.value}</option>
                                        )}
                                    </select>
                                </td>
                                <td className='text-center'>USD ${product.price * _.get(product, 'amount', 1)}</td>
                                <td className=''>
                                    <div className='h-full flex justify-center items-center'>
                                        <img className='w-[24px] h-[24px]' src={remove_frame_btn_img} alt='remove_frame_btn_img'
                                            onClick={() => {
                                                const d = _.filter(orderList, function(o) { return o.id != product.id; });
                                                setOrderList(d);
                                            }} />  
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>

                    <div style={{
                        fontSize: "1.5rem",
                        display: "flex",
                        padding: "20px"
                    }}>
                        <div className="col-6 d-flex justify-content-center">
                            總計 USD ${orderList.reduce((prev, product)=> {
                                return prev+(product.price * _.get(product, 'amount', 1));
                            }, 0)}
                        </div>
                        <div className="col-6 d-flex justify-content-center">
                            <Button onClick={()=>confirmBuy()}>前往結賬</Button>
                        </div>
                    </div>
                </>
                }
            </div>
        </div>
    </Content>
    );
}

export default ShoppingCartPage;

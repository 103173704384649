import React, { useEffect, useState } from 'react';
import { Carousel } from "react-bootstrap";
import { Layout, message } from 'antd';
import member_nigen from '../../assets/images/member_nigen.jpg';
import member_leo from '../../assets/images/member_leo.jpg';
import member_jeremy from '../../assets/images/member_jeremy.jpg';
import member_tommy from '../../assets/images/member_tommy.jpg';
import { useGetLocaleStr } from '../../utils/i18n';

const { Content } = Layout;

function MemberIntro() {
    const getLocaleStr = useGetLocaleStr();
    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Content
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: "column",
                    backgroundColor: '#000000', 
                    padding: "20px",
                }}
            >
                <div className='text-3xl w-full px-3'>
                    <div className='gradient-text w-fit'>{getLocaleStr("title-funding-motivation")}</div>
                </div>
                <div className="container">
                    <ControlledCarousel/>
                </div>
            </Content>
        </Layout>
    );
}


function ControlledCarousel() {
    const getLocaleStr = useGetLocaleStr();
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    const members = [
        {
            src: member_nigen,
            name: "Founder: Nigen Wu (吳祥銘)",
            text: getLocaleStr("desc-intro-nigen")
        },
        {
            src: member_leo,
            name: "Leo Chou (周庭宇)",
            text: getLocaleStr("desc-intro-leo") },
        {
            src: member_jeremy,
            name: "Jeremy fu (傅韋翔)",
            text: getLocaleStr("desc-intro-jeremy") },
        {
            src: member_tommy,
            name: "Tommy Lin (林棕閔)",
            text: getLocaleStr("desc-intro-tommy") },
    ]
    return (
        <Carousel
            fade
            activeIndex={index}
            onSelect={handleSelect}
            className="w-100" 
            style={{height: "500px"}}
        >
            {members.map((item: any, _index: number) => {
                return (
                    <Carousel.Item
                        className='w-full h-full text-gray-300 text-base bg-black'
                        key={_index}
                    >
                        <div className="w-full flex">
                            <div className="hidden h-full sm:w-2/5 sm:block">
                                <img
                                    src={item.src}
                                    className="block w-full m-auto"
                                    alt="..."
                                />
                            </div>
                            <div className='w-full sm:w-3/5 px-10'>
                                <h3>{item.name}</h3>
                                <div className="text">{item.text}</div>
                            </div>
                        </div>
                    </Carousel.Item>
                )
            })}
        </Carousel>
    );
}

export default MemberIntro;


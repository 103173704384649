import * as restAPI from '../utils/axiosManager';
import { VideoLikeResponse,UserFollowResponse,CheckVideoStatusResponse } from '../model/videoInfoModel';

//收藏某標籤

//取消收藏某標籤


const film_type = 0; //影片
const tag_type = 1;//標籤
const user_type = 2;//作者

//按讚某影片
const setLikeVideo = (id : any) => {
    return new Promise((resolve, reject) => {
        const url = `api/like`;

        const params: any = {};
        params['type'] = film_type;
        params['id'] = id;

        restAPI
        .request('post', url, params)
        .then((res: any) => {
            const data = res.data; //取得資訊
            if (data.error !== '') { //如果有錯誤的話
                reject(data.error);
            }
            //const videoLikeResponse = new VideoLikeResponse(data.data);
            resolve(data.data);
        })
        .catch((err: any) => {
            reject(err);
        });
        
    });
}

//回收按讚某影片
const cancelLikeVideo = (id : any) => {
    return new Promise((resolve, reject) => {
        const url = `api/like`;

        const params: any = {};
        params['type'] = film_type;
        params['id'] = id;


        restAPI
        .request('delete', url, params)
        .then((res: any) => {
            const data = res.data; //取得資訊
            if (data.error !== '') { //如果有錯誤的話
                reject(data.error);
            }
           
            //const videoLikeResponse = new VideoLikeResponse(data.data);
            resolve(data.data);
        })
        .catch((err: any) => {
            reject(err);
        });

    });
}

//關注某使用者
const setFollowUser = (id : any) => {
    return new Promise((resolve, reject) => {
        const url = `api/follow`;

        const params: any = {};
        params['type'] = user_type;
        params['id'] = id;

        restAPI
        .request('post', url, params)
        .then((res: any) => {
            const data = res.data; //取得資訊
            if (data.error !== '') { //如果有錯誤的話
                reject(data.error);
            }
            // const userFollowResponse = new UserFollowResponse(data.data);
            resolve(data);
        })
        .catch((err: any) => {
            reject(err);
        });

    });
}

//取消關注某用者
const cancelFollowUser = (id : any) => {
    return new Promise((resolve, reject) => {
        const url = `api/follow`;

        const params: any = {};
        params['type'] = user_type;
        params['id'] = id;


        restAPI
        .request('delete', url, params)
        .then((res: any) => {
            const data = res.data; //取得資訊
            if (data.error !== '') { //如果有錯誤的話
                reject(data.error);
            }
            //const userFollowResponse = new UserFollowResponse(data.data);
            resolve(data);
        })
        .catch((err: any) => {
            reject(err);
        });

    });
}

//檢查使用者是否已經關注此影片的創作者
//檢查使用者是否已經喜歡此影片
const checkStatusVideo = (film_id : any, user_id: any) => {
    return new Promise((resolve, reject) => {
        const url = `api/checkStatusVideo`;

        const params: any = {};
        params['film_id'] = film_id;
        params['user_id'] = user_id;


        restAPI
        .request('get', url, params)
        .then((res: any) => {
            const data = res.data; //取得資訊
            if (data.error !== '') { //如果有錯誤的話
                reject(data.error);
            }

            // console.log("check_like_follow_video " +data);
            //const checkVideoStatusResponse = new CheckVideoStatusResponse(data.data);
            resolve(data.data);
        })
        .catch((err: any) => {
            reject(err);
        });

    });
}

export {
    setLikeVideo,
    cancelLikeVideo,
    setFollowUser,
    cancelFollowUser,
    checkStatusVideo,
}
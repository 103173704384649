import React from "react";
import { Layout } from "antd";
import MyHeader from "../../components/header/Header";

const{ Content }= Layout;

const ConsumerRight = () => {
    return (
        <div style={{padding: '80px', textAlign: 'center'}}>
                <h1>消費者權益</h1>
                <div style={{textAlign: 'left'}}>
                <p style={{padding: '30px'}}>
                    <br />歡迎使用Morld服務，Morld是由「動世科技有限公司」（以下稱「本公司」）所建置之的運動平台，
                    為盡可能保護您的權益及確認契約關係，所有申請Morld服務(以下稱「本服務」)之使用者，
                    都應該詳細閱讀下列服務條款：
                    <br />
                    <br /><span style={{fontSize: '20px'}}>壹、客戶服務及權利義務</span>
                    <br />Morld主要提供您以下服務：
                    <br />(1).商品銷售、配送及網站所提供之服務，包含：
                    <br />&#8226;Morld運動課程。
                    <br />&#8226;客服人員線上諮詢。
                    <br />&#8226;虛擬通路商品配貨及免費退貨服務 (可參考退貨政策）。
                    <br />&#8226;信用卡支付。
                    <br />(2).我們的商品頁面會提供單一商品可選購數量上限供客戶參考，原則上我們僅在該數量上限內進行出貨。
                    <br />(3).商品運費計價方式將明載於網頁中，如未有記載將由本公司或商家負擔。
                    <br />(4).各類型行銷資訊提供。
                    <br />(5).客戶相關權益通知，包含但不限商品試用、抽獎活動、服務滿意度調查或其他未來新增之會員服務。
                    <br />(6).本公司得依實際情形，增加、修改或是終止上述相關服務。
                    <br />&#8226;當您於Morld購物平台任一管道完成註冊手續、或繼續使用Morld購物平台所提供之任一服務時，即視為已知悉並完全同意本服務條款的所有約定服務項目。另外，當您使用Morld平台特定服務時，可能會依據該特定服務之性質，而須遵守Morld所另行公告之服務條款或相關規定，此另行公告之服務條款或相關規定亦併入屬於本服務條款之一部分。
                    <br />&#8226;若您未滿十八歲，應於您的法定代理人閱讀、瞭解並同意本服務條款之所有內容及其後修改變更後，方得註冊使用或繼續使用。當您使用或繼續使用Morld購物平台所提供之任一服務時，即表示您的法定代理人已閱讀、瞭解並同意接受本服務條款之所有內容及其後修改變更。
                    <br />&#8226;客戶及Morld均同意以電子文件作為意思表示之方法。
                    <br />&#8226;本公司針對任一違反法律規定、未遵循雙方約定、惡意濫用服務權益之客戶，保有終止該客戶帳戶服務之權利。
                    <br />&#8226;本公司有權於未來任何時間基於需要修改本條款內容，並得將修改內容以電子郵件、電話、型錄、通信網路、網路公告或其他適當方式通知客戶。
                    <br />&#8226;本公司為提供服務之必要通知客戶相關訊息時，得以客戶所留存之任一聯繫方式為之，客戶之聯繫資料如有異動應隨時以登錄網站、電話通知等方式進行資料更新，維持資料之正確性、即時性及完整性，若因您資料錯誤、過期或其他非可歸責本公司的原因，致本公司送達的訊息無法接受，仍視為本公司業已完成該通知的送達。
                    <br />
                    <br /><span style={{fontSize: '20px'}}>貳、客戶帳號、密碼與安全</span>
                    <br />(1).如客戶透過本公司各通路註冊為Morld會員，手機號碼必須詳實填寫。
                    <br />(2).客戶註冊時必須填寫確實之個人資料，若發現有不實登錄時，本公司得暫停或終止您的客戶資格，若有違反中華民國相關法律，亦將依法追究。客戶應該妥善保管帳號，不可以將帳戶資訊洩露或提供給他人知道或使用；
                    <br />(3).以同一個客戶帳號使用本服務所進行的所有行為，都將被認為是該客戶本人的行為，應由該客戶依法負責。
                    <br />(4).客戶如果發現或懷疑有第三人使用其客戶帳號以及登入使用，應該立即通知本公司，本公司於知悉後將立即暫停該帳號所生交易之處理及後續利用。但客戶於通知前依法應負之法律上責任並不因此通知而免除。
                    <br />
                    <br /><span style={{fontSize: '20px'}}>參、客戶交易</span>
                    <br />(1).商品交易頁面呈現之商品名稱、價格、內容、規格、型號及其他相關資訊，皆為您與本公司締結契約之一部分。
                    <br />(2).本公司收到您下單(要約)後，仍需確認交易條件正確、供貨商品有庫存或服務可提供。如有無法接受訂單之異常情形，或您下單後未能完成正常付款，應視為訂單(買賣契約)全部自始不成立或失效，本公司得於合理期間內通知說明拒絕接受訂單。請您重新依需求下單訂購。
                    <br />(3).依據消費者保護法第19條第1、2、3項規定：
                    <br />Ⅰ．通訊交易或訪問交易之消費者，得於收受商品或接受服務後七日內，以退回商品或書面通知方式解除契約，無須說明理由及負擔任何費用或對價。
                    <br />Ⅱ．但通訊交易有合理例外情事者，不在此限。
                    <br />Ⅲ．前項但書合理例外情事，由行政院定之。
                    <br />因此契約成立並於您收受商品後，除非政府另有公告優先適用其他法令，原則上您享有前述消費者保護法第19條第1項解除契約之權利，如有退貨需求，請參閱本公司網站中之「退貨/退款政策」。
                    <br />(4).請注意如您是透過Morld商城之服務所產生之交易行為，買賣或其他合約均僅存在您與各該商家兩造之間。各該商家將就其商品、服務或其他交易標的物之品質、內容、運送、保證事項與瑕疵擔保責任等，向您事先詳細闡釋與說明並履行。您因前述買賣、服務或其他交易行為所產生之爭執，應向各該商家尋求救濟或解決之道。本公司僅提供Morld商城之平台供您與商家間進行交易，本公司並非交易之當事人，故絕不介入您與商家間的任何買賣、服務或其他交易行為，對於您所獲得的商品、服務或其他交易標的物亦不負任何擔保責任。
                    <br />(5).依照客戶指定配送之商品(約配商品)接獲訂單逾30日您未通知出貨及受領商品，為了保障您的權益，本公司得取消訂單，請客戶重新下單購買。
                    <br />
                    <br /><span style={{fontSize: '20px'}}>肆、付款相關權益：</span>
                    <br />本公司目前提供信用卡支付方式。
                    <br />
                    <br /><span style={{fontSize: '20px'}}>伍、客戶隱私權保障</span>
                    <br />(1).隱私權聲明政策
                    <br />關於您註冊或使用本服務時所提供之個人資料，本公司將依「客戶隱私權政策 」為利用與保護。
                    <br />(2).資料記錄有效性
                    <br />客戶使用本服務時，其使用過程中所有的資料記錄，以本服務資料庫所記錄之資料為準，如有任何糾紛，以本服務資料庫所記錄之電子資料為認定標準，但客戶如能提出其他資料並證明為真實者則不在此限。
                    <br />
                    <br /><span style={{fontSize: '20px'}}>陸、智慧財產權</span>
                    <br />本公司或所屬子公司之網站及相關通路所使用之軟體或程式、網站上所有內容，包括但不限於著作、圖片、檔案、資訊、資料、網站架構、網站畫面的安排、網頁設計，均由本公司或其他權利人依法擁有其智慧財產權，包括但不限於商標權、專利權、著作權、營業秘密與專有技術等。任何人不得逕自使用、修改、重製、公開播送、改作、散布、發行、公開發表、進行還原工程、解編或反向組譯。若您欲引用或轉載前述軟體、程式或網站內容，必須依法取得本公司或其他權利人的事前書面同意。尊重智慧財產權是您應盡的義務，如有違反，您應對本公司負損害賠償責任（包括但不限於訴訟費用及律師費用等）。
                    <br />
                    <br /><span style={{fontSize: '20px'}}>柒、暫停服務</span>
                    <br />本公司以目前一般認為合理之方式及技術，維護本服務之正常運作。但於下述情況，本公司將暫停或中斷本服務之全部或一部份：
                    <br />(1).對本服務相關軟硬體設備進行搬遷、更換、升級、保養或維修並已向客戶預先通知者；
                    <br />(2).使用者有任何違反政府法令或本使用條款情形；
                    <br />(3).天災或其他不可抗力之因素所導致之服務停止或中斷；
                    <br />(4).其他不可歸責於本公司之事由所致之服務停止或中斷；
                    <br />(5).非本公司所得控制之事由而致本服務資訊顯示不正確、或遭偽造、竄改、刪除或擷取、或致系統中斷或不能正常運作時。
                    <br />
                    <br /><span style={{fontSize: '20px'}}>捌、客戶責任與義務</span>
                    <br />客戶使用本公司所提供服務時，除應遵守中華民國相關法令及遵守國際網際網路安全規定與慣例外，不得有下列行為：
                    <br />&#8226;違反法律或利用本服務為侵犯或違反他人權利之行為。
                    <br />&#8226;傳輸或散佈電腦病毒、惡意程式碼或其他可能導致本公司服務或系統受到干擾，而影響本公司正常營運之不當行為。
                    <br />&#8226;擷取非經本公司或所有人事先同意或授權之資訊。
                    <br />如客戶之行為涉及不法時，除應自負相關法律責任外，本公司並將主動配合司法機關或相關主管機關調查處理。此外，如果您有違反上述條款的行為，本公司有權依本服務條款第壹條第六項規定，將您的帳戶進行終止或限制存取。
                    <br />
                    <br /><span style={{fontSize: '20px'}}>玖、本條款之效力、解釋、問題諮詢、準據法</span>
                    <br />(1).本契約條款中，任何條款之全部或一部分無效時，不影響其他約定之效力。
                    <br />(2).本契約條款如有疑義，將為有利於客戶之解釋。
                    <br />(3).客戶如對服務有相關問題，可透過客服信箱進行諮詢。
                    <br />(4).客戶與本公司之權利義務關係，應依網路規範及中華民國法令解釋及規章、慣例為依據處理。
                    <br />本公司的任何聲明、條款如有未盡完善之處，將以最大誠意，依誠實信用、平等互惠原則，共商解決之道。</p>
                    </div>
        </div>
    );
}
    

export default ConsumerRight;

import * as restAPI from '../utils/axiosABServer';

/*
{
    "code": "0836",
    "id": "ee1841e5-bd70-477f-b630-1d67ac4b67ef",
    "status": 0,
    "time": 1691222293.938093
}
*/


// 上傳 AB 影片資料
const uploadCompareVideoTask = (film_a : any, film_b: any,videoAtime:any,videoBtime:any) => {
    return new Promise((resolve, reject) => {

        // 
        //const url = `up`;
        const url = `up?a_begin_analyze_time=${videoAtime}&b_begin_analyze_time=${videoBtime}`;
        console.log("url = "+url);
        //
        // const params: any = {};
        // params['video_a'] = film_a;
        // params['video_b'] = film_b;

        const data = new FormData()
        data.append('video_a', film_a)
        data.append('video_b', film_b)
        //
        restAPI
        .request('post', url, data)
        .then((res: any) => {
            const data = res.data; //取得資訊
            console.log(res);
            console.log(res.data);
            //const videoLikeResponse = new VideoLikeResponse(data.data);
            resolve(data);
        })
        .catch((err: any) => {
            reject(err);
        });
      });
}

// 查詢系統是否忙碌
const searchSystemStatus = () => {
    return new Promise((resolve, reject) => {
        // 
        const url = `getBusy`;
        //
        restAPI
          .request('get', url, {})
          .then((res: any) => {
            resolve(res.data);
          })
          .catch((err: any) => {
            reject(err);
          });
    });
} 

// 查詢 AB 影片資料
const searchCompareVideoTask = (task_id : any) => {

    return new Promise((resolve, reject) => {
        // 
        const url = `getWork?code=${task_id}`;
        //
        restAPI
          .request('get', url, {})
          .then((res: any) => {
            
            resolve(res.data);
          })
          .catch((err: any) => {
            reject(err);
          });
    });
}

// 下載 AB 影片資料
const downloadCompareVideoTask = (film_id : any) => {
    // return new Promise((resolve, reject) => {
    // });
}


export {
    uploadCompareVideoTask,
    searchCompareVideoTask,
    downloadCompareVideoTask,
    searchSystemStatus,
}
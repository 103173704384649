import { loginActionTypes, SETLOGIN } from '../actions/login';

const isLoggedReducer = (state = {}, action: loginActionTypes): any => {
  switch (action.type) {
    case SETLOGIN:
      return {
        isLogin: action.payload.isLogin,
        mail: action.payload.mail,
        token: action.payload.token,
        data: action.payload.data,
      };
    default:
      return state;
  }
};
export default isLoggedReducer;

import React, { useState, useEffect } from 'react';
import { Layout, message } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as authAPI from '../api/AuthApi';
//css
import '../assets/css/LoginPage.css';
//image
import pre_home_logo from '../assets/images/pre_home_morld_logo.png';
import login_welcome_to from '../assets/images/login_welcome_to.png';
import login_morld from '../assets/images/login_morld.png';
//component
import MyHeader from '../components/header/Header';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
//state
import { useDispatch } from 'react-redux';
import { setLogin } from '../actions/login';
import { StorageKey } from '../core/config';
import Swal from 'sweetalert2'

const { Content } = Layout;

function LoginPage() {
  const [messageApi, contextHolder] = message.useMessage();
  // const isLogin = useSelector((state: stroeTypes) => state.isLoggedReducer);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [messageMail, setMailMessage] = useState('');
  //正確
  const [successMessageCaptcha, setSuccessCaptchaMessage] = useState('');
  //錯誤
  const [errorMessageCaptcha, setErrorCaptchaMessage] = useState('');

  const [isMailValid, setIsMailValid] = useState(false);
  const [isMailInvalid, setIsMailInvalid] = useState(false);
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const [isCaptchaInvalid, setIsCaptchaInvalid] = useState(false);

  useEffect(() => {
    console.log(searchParams.get('error'))
    const err:string = searchParams.get('error');
    if(err != null)
    {
      Swal.fire({
        position: "center",
        icon: "error",
        title: err,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }, [searchParams]);

  //註冊/登入索取驗證碼事件
  const register_api = (email: string) => {
    // console.log("mail =>"+mail);

    setIsMailValid(false);
    setIsMailInvalid(false);
    setIsCaptchaValid(false);
    setIsCaptchaInvalid(false);
    // setEmail('')
    setCaptcha('');
    setMailMessage('');
    setSuccessCaptchaMessage('');
    setErrorCaptchaMessage('');

    //檢查mail格式
    const validRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email.match(validRegex)) {
      setIsMailValid(true);
      authAPI
        .register(email)
        .then((data: any) => {
          setIsCaptchaValid(true);
          setSuccessCaptchaMessage(data.message);
        })
        .catch((error: any) => {
          messageApi.error(error);
          setMailMessage('電子郵件資訊有誤，請檢查欄位是否正確');
        });
    } else {
      setMailMessage('電子郵件資訊有誤，請檢查欄位是否正確');
      setIsMailInvalid(true);
    }
  };
  //登入事件
  const login_event = async (email: string, captcha: string) => {
    setIsMailValid(false);
    setIsMailInvalid(false);
    setIsCaptchaValid(false);
    setIsCaptchaInvalid(false);
    setSuccessCaptchaMessage('');
    setErrorCaptchaMessage('');

    //檢查mail格式
    const validRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email.match(validRegex)) {
      setIsMailValid(true);
    } else {
      setMailMessage('電子郵件資訊有誤，請檢查欄位是否正確');
      setIsMailInvalid(true);
    }

    const regex = /^\d{6}$/;

    if (regex.test(captcha)) {
      setSuccessCaptchaMessage('驗證碼資料已輸入');
      setIsCaptchaValid(true);
    } else {
      setErrorCaptchaMessage('驗證碼資訊有誤，請檢查欄位是否正確');
      setIsCaptchaInvalid(true);
    }

    if (isMailValid && isCaptchaValid) {
      authAPI
        .login(email, captcha)
        .then((data: any) => {
          if (data.error === '') {
            dispatch(setLogin(true, email, data.data.token, data.data));
            localStorage.setItem(StorageKey.TOKEN_KEY, data.data.token);
            navigate('/member_main');
          } else {
            setMailMessage('電子郵件資訊有誤，請檢查欄位是否正確');
            setIsMailInvalid(true);
            setErrorCaptchaMessage('驗證碼資訊有誤，請檢查欄位是否正確');
            setIsCaptchaInvalid(true);
          }
        })
        .catch(() => {
          setMailMessage('電子郵件資訊有誤，請檢查欄位是否正確');
          setIsMailInvalid(true);
          setErrorCaptchaMessage('驗證碼資訊有誤，請檢查欄位是否正確');
          setIsCaptchaInvalid(true);
        });
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    login_event(email, captcha);
  };

  const handleClick = (e: any) => {
    e.preventDefault();
    register_api(email);
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {contextHolder}
      <MyHeader />
      <Content
        style={{
          backgroundColor: '#1B1B1B',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            backgroundColor: '#110E0C',
            borderRadius: '10px',
            width: '600px',
            // height: '550px',
            padding: '20px',
          }}
        >
          <div
            style={{
              display: 'flex',
              marginBottom: '20px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {/* <div style={{ flex: 0.5, display: 'flex', justifyContent: 'flex-end' }}>
              
            </div> */}
            <img
              style={{ height: '80px', marginRight: '10px' }}
              src={pre_home_logo}
              alt='radar_img'
            />
            {/* <div style={{ flex: 0.5, display: 'flex', justifyContent: 'flex-start' }}> */}
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img
                  className='img-fluid'
                  src={login_morld}
                  style={{ height: '60px' }}
                  alt='radar_img'
                />
              </div>
            </div>
            {/* </div> */}
          </div>
          <Form noValidate onSubmit={handleSubmit}>
            {/* 輸入信箱 */}
            <div style={{ marginBottom: '0px', height: '70px' }}>
              <InputGroup size='lg'>
                <InputGroup.Text id='inputGroup-sizing-lg'>輸入信箱</InputGroup.Text>
                <Form.Control
                  required
                  isValid={isMailValid}
                  isInvalid={isMailInvalid}
                  aria-label='Large'
                  aria-describedby='inputGroup-sizing-sm'
                  type='email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ borderRadius: '0 0.5rem 0.5rem 0' }}
                />
                <Form.Control.Feedback type='invalid'>
                  <div className='left-div'>
                    <p>{messageMail}</p>
                  </div>
                </Form.Control.Feedback>
                <Form.Control.Feedback>
                  <div className='left-div'>
                    <p>已輸入電子郵件輸入</p>
                  </div>
                </Form.Control.Feedback>
              </InputGroup>
            </div>

            <div style={{ marginBottom: '10px', height: '70px' }}>
              {/* 驗證碼 */}
              <InputGroup size='lg'>
                <Form.Control
                  required
                  value={captcha}
                  isValid={isCaptchaValid}
                  isInvalid={isCaptchaInvalid}
                  placeholder='驗證碼'
                  aria-label="Recipient's username"
                  aria-describedby='basic-addon2'
                  onChange={(e) => setCaptcha(e.target.value)}
                />
                <Button className="text-black font-bold"
                  variant='btn btn-primary'
                  type='button'
                  id='button-addon2'
                  onClick={handleClick}
                  style={{ borderRadius: '0 0.5rem 0.5rem 0' }}
                >
                  發送
                </Button>
                <Form.Control.Feedback type='invalid'>
                  <div className='left-div'>
                    <p>{errorMessageCaptcha}</p>
                  </div>
                </Form.Control.Feedback>
                <Form.Control.Feedback>
                  <div className='left-div'>
                    <p>{successMessageCaptcha}</p>
                  </div>
                </Form.Control.Feedback>
              </InputGroup>
            </div>

            {/* 註冊/登入 */}
            <div className='col-lg-12 text-center '>
              <p style={{ color: '#FFFFFF', fontSize: '20px' }}>註冊/登入</p>
            </div>
            {/* 確認按鈕 */}
            <div className='col-lg-12 text-center ' style={{ paddingBottom: '2vh' }}>
              <button className='btn btn-primary btn-lg text-black font-bold' type='submit' style={{ width: '100%' }}>
                確認
              </button>
            </div>
          </Form>
        </div>
      </Content>
    </Layout>
  );
}

export default LoginPage;

import React, { useState, useEffect, useId, useRef } from 'react';
import { Layout, Space, Row, Col, message, Pagination } from 'antd';
import { useGetLocaleStr } from '../utils/i18n';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from "lodash";
import { stroeTypes } from '../reducers/configureStore';
import Swal from 'sweetalert2'
//css
import '../assets/css/LoginPage.css';
import '../assets/css/main_home.css';
import '../styles/Home.css';
import 'react-spring-bottom-sheet/dist/style.css'
import { BottomSheet } from 'react-spring-bottom-sheet'
const { Content } = Layout;
import CountrySelector from "../components/country-picker/selector";
import { COUNTRIES } from "../components/country-picker/countries";
import { SelectMenuOption } from "../components/country-picker/types";
import { SegmentedControl } from 'segmented-control'

export default function BuyInfo({ show, onDismiss, onDone }) {

    const [data, setData] = useState({ 'country': 'TW' });
    const [country, setCountry] = useState('TW');
    const [showChoiceCountry, setShowChoiceCountry] = useState<boolean>(false);

    return (
        <BottomSheet open={show} className='w-full' onDismiss={() => {
            onDismiss()
        }}>
            <div className="flex  justify-center items-center w-full ">
                <div className="flex flex-col flex-wrap justify-center items-center text-xs w-2/3 gap-3 my-8">

                    <div className={"w-full text-lg"}>
                        <label className="block text-lg font-medium text-gray-700">
                            選擇國籍
                        </label>
                        <CountrySelector
                            id={"country-selector"}
                            open={showChoiceCountry}
                            onToggle={() => setShowChoiceCountry(!showChoiceCountry)}
                            onChange={(e) => {
                                setCountry(e)
                                setData(prevState => ({ ...prevState, country: (e) }));
                            }}
                            selectedValue={COUNTRIES.find((option) => option.value === country)}
                        />
                    </div>
                    <div className={"w-full text-lg"}>
                        <label className="block text-lg font-medium text-gray-700">
                            輸入身分證字號
                        </label>
                        <input placeholder='' className="px-2 py-2 justify-center items-start w-full text-base text-black whitespace-nowrap rounded-xl border-2 border-solid border-zinc-300" value={_.get(data, 'nid', '')} onChange={(e) => {
                            setData(prevState => ({ ...prevState, nid: (e.target.value) }));
                        }} />
                    </div>

                    <div className={"w-full text-lg"}>
                        <label className="block font-medium text-gray-700">
                            輸入姓名
                        </label>
                        <input className="px-2 py-2 w-full text-black whitespace-nowrap rounded-xl border-2 border-solid border-zinc-300" value={_.get(data, 'name', '')} onChange={(e) => {
                            setData(prevState => ({ ...prevState, name: (e.target.value) }));
                        }} />
                    </div>

                    <SegmentedControl
                        name="oneDisabled"
                        options={[
                            { label: "統一編號", value: "統一編號" },
                            { label: "手機載具", value: "手機載具" },
                            { label: "皆不需要", value: "", default: true }
                        ]}
                        setValue={(e) => {
                            console.log(e)
                            setData(prevState => ({ ...prevState, invoice_type: (e) }));
                        }}
                    />

                    {_.get(data, 'invoice_type', '') === '統一編號' &&
                        <div className={"w-full text-lg"}>
                            <label className="block font-medium text-gray-700">
                                輸入統一編號
                            </label>
                            <input className="px-2 py-2 w-full text-black whitespace-nowrap rounded-xl border-2 border-solid border-zinc-300" value={_.get(data, 'tax_id', '')} onChange={(e) => {
                                setData(prevState => ({ ...prevState, tax_id: (e.target.value) }));
                            }} />
                        </div>}

                    {_.get(data, 'invoice_type', '') === '手機載具' &&
                        <div className={"w-full text-lg"}>
                            <label className="block font-medium text-gray-700">
                                輸入手機載具
                            </label>
                            <input className="px-2 py-2 w-full text-black whitespace-nowrap rounded-xl border-2 border-solid border-zinc-300" value={_.get(data, 'carriers', '')} onChange={(e) => {
                                setData(prevState => ({ ...prevState, carriers: (e.target.value) }));
                            }} />
                        </div>}

                    <div onClick={() => {
                        if (_.get(data, 'name', '') === '') {
                            alert('請輸入姓名')
                            return;
                        }
                        if (_.get(data, 'nid', '') === '') {
                            alert('請輸入身分證')
                            return;
                        }
                        if (_.get(data, 'invoice_type', '') === '統一編號' && _.get(data, 'tax_id', '') === '') {
                            alert('請輸入統一編號')
                            return;
                        }
                        if (_.get(data, 'invoice_type', '') === '手機載具' && _.get(data, 'carriers', '') === '') {
                            alert('請輸入手機載具')
                            return;
                        }
                        onDismiss()
                        onDone(data)
                    }} className="flex justify-center items-center py-2 mt-2.5 w-full text-2xl text-black bg-blue-100 rounded-xl">
                        確認
                    </div>
                </div>
            </div>
        </BottomSheet>
    );
}
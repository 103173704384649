import * as restAPI from '../utils/axiosManager';

const register = (email: string) => {
  return new Promise((resolve, reject) => {
    const dormData = new FormData();
    dormData.append('email', email);
    restAPI
      .request('post', 'api/register', dormData)
      .then((res: any) => {
        resolve(res.data);
        //console.log(res.data);
      })
      .catch((err: any) => {
        reject(err);
        //console.log(err.data);
      });
  });
};

const login = (email: string, captcha: string) => {
  return new Promise((resolve, reject) => {
    const dormData = new FormData();
    dormData.append('email', email);
    dormData.append('captcha', captcha);
    restAPI
      .request('post', 'api/verifyLogin', dormData)
      .then((res: any) => {
        resolve(res.data);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

const loginWithToken = () => {
  return new Promise((resolve, reject) => {
    const dormData = new FormData();
    restAPI
        .request('post', 'api/verifyLoginWithToken', dormData)
        .then((res: any) => {
          resolve(res.data);
        })
        .catch((err: any) => {
          reject(err);
        });
  });
};

export { register, login, loginWithToken };

import React, { useEffect, useRef, useState } from 'react';
import MyHeader from '../../components/header/Header';
import Starter from './Starter';
import IntroVideo from './introVideo';
import MemberIntro from './memberIntro';
import Footer from '../../components/Footer';
import arrow_down from '../../assets/images/arrow-down.png';
import { animateScroll as scroll } from 'react-scroll';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import * as Utils from '../../utils/Utils';
import {useDispatch} from "react-redux";

export const msleep = async (milisecond: number) => {
    return new Promise((resolve) => setTimeout(resolve, milisecond));
}

const Intruduction = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const scrollTarget = useRef(window.innerHeight || 800);
    const dispatch = useDispatch();

    useEffect(() => {
        const pay_done = searchParams.get('pay_done');
        if (pay_done != null) {
            Swal.fire({
                title: '成功',
                text: pay_done,
                icon: 'success',
                confirmButtonText: 'OK'
            })
            return;
        }
        const token = searchParams.get('token');
        Utils.loginWithToken(dispatch, token);
    }, [searchParams]);

    const handleScroll = async () => {
        await msleep(1000);
        scroll.scrollTo(scrollTarget.current, { duration: 100 });
        scrollTarget.current += window.innerHeight;
    }

    return (
        <>
            <a
                href=""
                style={{
                    display: "block",
                    position: "fixed",
                    bottom: "7%",
                    right: "7%",
                    zIndex: 65535,
                    width: "50px"
                }}
                onClick={(e) => { e.preventDefault(); handleScroll(); }}>
                <img src={arrow_down} alt="arrow-down" style={{ width: "100%" }} />
            </a>
            <MyHeader />
            <Starter />
            <IntroVideo />
            <MemberIntro />
            <Footer />
        </>
    )
}

export default Intruduction;
import { useTranslation } from 'react-i18next';
import { languageList } from '../core/config';

export const useGetLocaleStr = () => {
    const { t } = useTranslation();
    return function (id: string, options?: any) {
        return `${t(id, options, { useSuspense: false })}`;
    }
}

export const useChangeLocale = () => {

    const { t, i18n } = useTranslation();

    return function (lang: string) {
        i18n.changeLanguage(lang);
    }
}
import React from "react";

const ReturnPolicy = () => {
    return (
       <div style={{padding: '80px', textAlign: 'center'}}>
        <h1>退貨政策</h1>
        <div style={{textAlign: 'left'}}>
        <p>
            <br />我們尊重您的消費權益，並依據消費者保護法第19條第1項的規定，提供您網路購物的特殊解約權。您在收到商品後7天內，可以不附理由、不負擔任何費用，與我們解除契約並取回款項。但以下情形除外：

            <br />1. 依法不得要求退貨者。
            <br />2. 商品已拆封、使用或有明顯破損者。
            <br />3. 商品屬於個人衛生用品或易腐敗品者。
            <br />4. 商品屬於客製化或訂製品者。
            <br />5. 其他依法或經主管機關公告不適用解約權之商品。
            <br />6. 自購買日起超過7天之商品。
            <br />7. 包含但不限於禮品、禮品卡、點數、某些健康及個人照護商品、可供下載的影片、書籍、軟體檔案及視訊。
            <br />
            <br />• 若您要求退貨，請先與我們聯絡確認退貨事宜，並將商品連同發票一併寄回。我們收到商品後，會盡快為您辦理退款程序。若您是以信用卡付款，則會通知發卡銀行取消授權；若您是以其他方式付款，則會將款項匯入您指定的帳戶。

            <br />• 若您要求換貨，請先與我們聯絡確認換貨事宜，並將商品連同發票一併寄回。我們收到商品後，會盡快為您辦理換貨程序。若換貨之商品價格高於原購買之商品價格時，需補足差額；反之則無需退還差額

            <br />• 在某些情況下，僅提供部分退款：(如適用)
            <br />&nbsp;&nbsp;&nbsp;&nbsp;(1). 有明顯使用痕跡的書籍
            <br />&nbsp;&nbsp;&nbsp;&nbsp;(2). 已開封的商品
            <br />&nbsp;&nbsp;&nbsp;&nbsp;(3). 任何非原始狀態的商品，包括非本公司錯誤所導致的損壞或零件遺失。
            <br />
            <br />本政策所稱之「收到商品」係指消費者或其指定人員實際取得該商品之日。

            <br />本政策所稱之「7天」係指自消費者或其指定人員收到該商品起算至次日零時起算至第7日24時止。

            <br />本政策所稱之「不附理由、不負擔任何費用」係指消費者在鑑賞期內解除契約時，無須說明理由及支付任何違約金、利息、手續費等金錢給付義務；但因解除契約而產生必要之運送及保管等支出仍由消費者自行承擔。

            <br />本政策所稱之「依法不得要求退貨」係指依消保法第19條第2項規定及主管機關公告而不適用特殊解約權之情形。
        </p>
        </div>
    </div>
    );
}
    

export default ReturnPolicy;

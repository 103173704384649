import React, { useState, useEffect, useRef } from 'react';
import {
    Layout,
    Space,
    Button,
    Row,
    message,
    Pagination,
    Avatar } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { useGetLocaleStr } from '../utils/i18n';
import { VideoInfo, Tag } from '../model/videoInfoModel';
import * as mediaAPI from '../api/MediaApi';
import ReactPlayer from 'react-player';
import VideoCard from '../components/video_card';

import '../assets/css/main_home.css';
import '../styles/Home.css';


const { Content } = Layout;

function Home() {
    const refHitVideo = useRef(null);
    const getLocaleStr = useGetLocaleStr();
    const [messageApi, contextHolder] = message.useMessage();
    /* eslint-disable */
    const [searchParams, setSearchParams] = useSearchParams();
    /* eslint-enable */

    const [hitVideo, setHitVideo] = useState<VideoInfo>();

    const [recommandedTags, setRecommandedTags] = useState<Tag[]>([]);
    const [videoList, setVideoList] = useState<VideoInfo[]>([]);
    const [isMuted, setIsMuted] = useState(true);

    const [selectedTag, setSelectedTag] = useState<number>(-1);
    const [searchQuery, setSearchQuery] = useState<string>('');

    // pagination
    const [current, setCurrent] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [total, setTotal] = useState<number>(0);

    useEffect(() => {
    const search_query = searchParams.get('search_query');
    if (search_query) {
        setSearchQuery(search_query);
    }
    const tag = searchParams.get('tag');
    if (tag) {
        setSelectedTag(Number(tag));
    } else {
        setSelectedTag(-1);
    }
    }, [searchParams]);

    useEffect(() => {
    get_video_list();
    }, [selectedTag, searchQuery, current]);

    const get_recommanded_tags = () => {
    mediaAPI
        .getTags()
        .then((tags: any) => {
        // push default tag
        const default_tag = new Tag(-1, '全部');
        default_tag.selected = true;
        tags.unshift(default_tag);
        setRecommandedTags(tags);
        })
        .catch((error: any) => {
        messageApi.error(error);
        });
    };

    const get_random_hit_video = () => {
      mediaAPI
        .getHitVideo()
        .then((data: any) => {
          console.log(['get_random_hit_video', data]);
          setHitVideo(data);
        })
        .catch((error: any) => {
          messageApi.error(error);
        });
    };

    const get_video_list = () => {
    mediaAPI
        .getVideos(current, selectedTag, searchQuery, '')
        .then((data: any) => {
        const total_video = data.data.total;
        const page_size = data.data.per_page;

        const video_list: VideoInfo[] = [];
        for (let i = 0; i < data.data.data.length; i++) {
            video_list.push(new VideoInfo(data.data.data[i]));
        }

        setVideoList(video_list);

        setPageSize(page_size);
        setTotal(total_video);
        })
        .catch((error: any) => {
        messageApi.error(error);
        });
    };

    const button_unselect_style = {
    marginRight: '5px',
    backgroundColor: 'rgba(121, 121, 121, 0.4)',
    border: '0',
    color: '#FFFFFF',
    };

    const button_select_style = {
    marginRight: '5px',
    backgroundColor: '#FFFFFF',
    border: '0',
    color: 'rgba(121, 121, 121, 1)',
    };

    const select_tag = (id: number) => {
    const tag_list = [...recommandedTags];
    for (let i = 0; i < tag_list.length; i++) {
        if (tag_list[i].id === id) {
        tag_list[i].selected = true;
        } else {
        tag_list[i].selected = false;
        }
    }
    setSelectedTag(id);
    setRecommandedTags(tag_list);
    };

    useEffect(() => {
    get_recommanded_tags();
    get_random_hit_video();
    }, []);

    return (
        <Content
            style={{
                backgroundColor: '#1b1b1b',
                display: 'flex',
                flexDirection: 'column',
                boxSizing: "border-box",
                minHeight: "unset"
            }}
        >
            
            {contextHolder}
            <div className='suggest_block'>
                <div className='d-flex flex-column mr-0 mt-1'>
                    <div style={{ flexGrow: 1 }}></div>
                </div>
                <Space size={[0, 8]} wrap style={{padding: "0 10px"}}>
                    {recommandedTags.map((e: Tag) => {
                        return (
                            <Button
                                style={e.selected ?
                                    button_select_style :
                                    button_unselect_style
                                }
                                key={e.id}
                                onClick={() => {
                                    select_tag(e.id);
                                }}
                            >
                            {e.name}
                            </Button>
                        );
                    })}
                </Space>
                </div>
            <div>
                <div
                    ref={refHitVideo}
                    className='random-hit-video h-[500px]'
                    style={{
                        position: 'relative',
                        backgroundColor: '#000000',
                    }}
                >

                    {hitVideo && (
                        <ReactPlayer
                            width="100%"
                            height='100%'
                            url={hitVideo.video_slug}
                            playing
                            muted={isMuted} 
                            loop={true}
                        />
                    )}

                </div>
                <div className='title_video_block_bottom_top flex align-items-center text-gray-300 text-xl gap-2'>
                    {hitVideo?.user_info.avatar_image_slug != "https://storage.googleapis.com/morld_storge/" ?
                        <Avatar
                            size={45}
                            src={
                                <img
                                    src={hitVideo?.user_info.avatar_image_slug}
                                    alt="avatar"
                                />
                            } 
                        /> :
                        <Avatar
                            size={45}
                            icon={<UserOutlined />}
                        />
                    }
                    {hitVideo?.title}
                </div>
                <div style={{ paddingTop: '20px' }}>
                    <div className='video_group'>
                        <Row style={{padding: "0 10px"}}>
                            {videoList.map((video: VideoInfo) => {
                                return (
                                    <VideoCard video={video} key={video.id} />
                                );
                            })}
                        </Row>
                        <Row style={{ marginBottom: '30px' }}>
                            <div style={{ margin: 'auto' }}>
                                <Pagination
                                    hideOnSinglePage={true}
                                    current={current}
                                    pageSize={pageSize}
                                    total={total}
                                    onChange={(page: number, pageSize: number) => {
                                        setPageSize(pageSize);
                                        setCurrent(page);
                                    }}
                                />
                            </div>
                        </Row>
                    </div>
                </div>
            </div>
            
        </Content>
    );
}

export default Home;

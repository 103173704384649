import React, { useEffect, useState } from 'react';
import { Button, Layout, message, Image, Avatar } from 'antd';
import { useSearchParams, useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { UserOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2'
import { stroeTypes } from '../reducers/configureStore';
import {useDispatch, useSelector} from 'react-redux';
import * as authAPI from '../api/AuthApi';
import * as Utils from '../utils/Utils';
import {
  VideoInfo,
  ViewCountResponse,
} from '../model/videoInfoModel';
import * as mediaAPI from '../api/MediaApi';
import * as userAPI from '../api/UserApi';
import * as viewerApi from '../api/ViewerApi';
import * as commentApi from '../api/CommentApi';

import heart_img from '../assets/images/heart.png';
import TestPage from './TestPage';
import MainTemplate from './mainTemplate';
import { StorageKey } from '../core/config';
import YouTube from 'react-youtube';
const { Content } = Layout;
import _ from "lodash";
import { addToShoppingCart } from '../utils/shoppingCart';
import {setLogin} from "../actions/login";

function VideoPlayPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    /* eslint-disable */
    const [searchParams, setSearchParams] = useSearchParams();
    /* eslint-enable */
    const [videoId, setVideoId] = useState<string>('');
    const [video, setVideo] = useState<VideoInfo>();

    const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
    const [isLiked, setIsLiked] = useState<boolean>(false);

    const [followCount, setFollowCount] = useState<number>(0);
    const [likeCount, setLikeCount] = useState<number>(0);

    const [isOperate, setIsOperate] = useState<boolean>(false);

    const [viewCounts, setViewCounts] = useState<ViewCountResponse>();
    const [currentTime, setCurrentTime] = useState<number>(0);
    const [inputCounts, setinputCounts] = useState<boolean>(false);
    const [commentFilm, setCommentFilm] = useState<any>([]);
    const [comment, setComment] = useState('');
    const [token, setToken] = useState<any>(null);
    const [playerIndex, setPlayerIndex] = useState<any>(1);

    useEffect(() => {
    const video_id = searchParams.get('v');
    const token = searchParams.get('token');
    if (video_id) {
        setVideoId(video_id);
    }
    Utils.loginWithToken(dispatch, token);
    }, [searchParams]);

    useEffect(() => {
        if (videoId) {
            mediaAPI
            .getVideosById(videoId)
            .then((data: any) => {
                setVideo(data);
            })
            .catch((error: any) => {
                messageApi.error(error);
            });

            //get_like_video(videoId);
            //get_subscribe_author('0');
        }
    }, [videoId]);

    useEffect(() => {
        if (video) {
            console.log(['video=', video]);
            check_like_follow_video(video?.id, video?.user_info.id);

            setLikeCount(video?.like_count);
            setViewCounts({
            view_count: 0,
            });
            viewerFirstCount(video?.uuid);
            commentVideoApi(video?.uuid);

            console.log(video?.user_info);
        }
        setToken(localStorage.getItem(StorageKey.TOKEN_KEY));
    }, [video]);

    const commentVideoApi = (video_uuid: any) => {
        commentApi
            .getFilmComments(video_uuid)
            .then((data: any) => {
                if (data.length === 0) {
                    setCommentFilm([]);
                } else {
                    setCommentFilm(data);
                }
            })
            .catch((error: any) => {
                setIsOperate(false);
            });
    };

    const viewerFirstCount = (video_uuid: any) => {
        viewerApi
            .getViewerVideoCount(video_uuid)
            .then((data: any) => {
                setViewCounts(data);
            })
            .catch((error: any) => {
                setIsOperate(false);
            });
    };

    const viewCount = (video_uuid: any) => {
        viewerApi
            .getViewerVideo(video_uuid)
            .then((data: any) => {
                setViewCounts(data);
            })
            .catch((error: any) => {
                setIsOperate(false);
            });
    };

    const handleProgress = (state: any) => {
        setCurrentTime(state.playedSeconds);

        if (state.playedSeconds >= 3 && !inputCounts) {
            viewCount(video?.uuid);
            setinputCounts(true);
        }
    };

    const check_like_follow_video = (video_id: any, user_id: any) => {
        userAPI
            .checkStatusVideo(video_id, user_id)
            .then((data: any) => {
            //console.log("check_like_follow_video " +data.isLiked);
                if (data.isLiked) {
                    setIsLiked(true);
                } else {
                    setIsLiked(false);
                }
                if (data.isFollowed) {
                    setIsSubscribed(true);
                } else {
                    setIsSubscribed(false);
                }
                setIsOperate(true);
            })
            .catch((error: any) => {
                setIsOperate(false);
            // messageApi.error("check_like_follow_video : "+error);
            });
        return false;
    };

    // Get the info that whether user has liked this video or not
    const get_like_video = (video_id: string) => {
    // api/like
        return false;
    };

    // Get the info that whether the user has subscribed to the author or not
    const get_subscribe_author = (author_id: string) => {
    // api/follow
        return false;
    };

    //關注創作者事件
    const subscribe_onclick = () => {
        if (isSubscribed) {
            //已訂閱過

            //console.log("關注創作者事件 = "+video?.user_info.id);
            userAPI
            .cancelFollowUser(video?.user_info.id)
            .then((data: any) => {
                setFollowCount(data.total_count);
            })
            .catch((error: any) => {
                messageApi.error(error);
            });
        } else {
            //未訂閱過

            userAPI
            .setFollowUser(video?.user_info.id)
            .then((data: any) => {
                setFollowCount(data.total_count);
            })
            .catch((error: any) => {
                messageApi.error(error);
            });
        }
        setIsSubscribed(!isSubscribed);
    };

    //喜歡影片事件
    const like_onclick = () => {
        if (isLiked) {
            //已喜歡過

            userAPI
            .cancelLikeVideo(video?.id)
            .then((data: any) => {
                console.log('data.total_count = ' + data.total_count);
                setLikeCount(data.total_count);
            })
            .catch((error: any) => {
                messageApi.error(error);
            });
        } else {
            //未喜歡

            userAPI
            .setLikeVideo(video?.id)
            .then((data: any) => {
                setLikeCount(data.total_count);
            })
            .catch((error: any) => {
                messageApi.error(error);
            });
        }

        setIsLiked(!isLiked);
    };

    const isLogin = useSelector((state: stroeTypes) => state.isLoggedReducer);

    const handleChange = (event: any) => {
        setComment(event.target.value);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();

        if (video) {
            commentApi
            .postFilmComment(video?.uuid, comment)
            .then((data: any) => {
                commentVideoApi(video?.uuid);
                messageApi.success('評論成功');
                setComment('');
            })
            .catch((error: any) => {
                messageApi.error(error);
            });
        }
    };

    return (
    <MainTemplate
        component={
        <>
            {contextHolder}
            <Layout style={{ minHeight: '100vh' }}>
            <Content
                style={{
                backgroundColor: '#1b1b1b',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                color: '#FFFFFF',
                overflow: 'auto',
                }}
            >
                <div className=""
                    style={{
                        backgroundColor: '#000000',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: '10px',
                        height: '600px'
                    }}
                >
                {_.get(video,'image_type','') == 'A' && (
                    <ReactPlayer
                        height='100%'
                        width='100%'
                        url={video.video_slug}
                        controls
                        onProgress={handleProgress}
                    />
                )}
                {_.get(video,'image_type','') == 'B' && (
                    <Image height='100%' src={video?.present_image_slug} />
                )}
                {(_.get(video, 'image_type', '') == 'C' || _.get(video, 'image_type', '') == 'D') && (
                    <div className='w-full h-full'>
                        {playerIndex == 1 &&
                            <YouTube className='w-full h-full' videoId={video.yt1} opts={{
                                height: '100%',
                                width: '100%',
                                playerVars: {
                                    autoplay: 1,
                                }
                            }} />
                        }
                        {playerIndex == 2 &&
                            <YouTube className='w-full h-full' videoId={video.yt2} opts={{
                                height: '100%',
                                width: '100%',
                                playerVars: {
                                    autoplay: 1,
                                }
                            }} />
                        }
                    </div>
                )}
                </div>
                <div
                    style={{
                        padding: '0 2rem',
                    }}
                >

                <div className="flex flex-col gap-3">

                    <button onClick={()=>{
                        if(playerIndex == 1) setPlayerIndex(2);
                        else setPlayerIndex(1);
                    }} type='button' className='btn btn-primary'>
                        切換影片
                    </button>

                    <div className="flex flex-col sm:flex-row sm:justify-between">
                        <div className="text-2xl">{video?.title}</div>
                        <div className="flex">
                            <img className="w-[24px] h-[24px]" src={heart_img} alt=''></img>
                            <div>{likeCount}</div>
                            <p className="ml-2">觀看次數: {viewCounts?.view_count}</p>
                        </div>
                    </div>
                </div>

                <div style={{ display: 'flex', marginTop: '10px' }}>
                    <div className="flex gap-2 flex-col sm:flex-row sm:justify-between">

                        <div className="flex">
                            <div style={{ marginRight: '10px' }}>
                                {video?.user_info.avatar_image_slug !=
                                    'https://storage.googleapis.com/morld_storge/' ? (
                                    <Avatar
                                        size={64}
                                        src={<img src={video?.user_info.avatar_image_slug} alt='avatar' />}
                                    />
                                ) : (
                                    <Avatar size={64} icon={<UserOutlined />} />
                                )}
                            </div>

                            <div className="h-full flex flex-col justify-center">
                                {video ? <div className="text-base">@{video?.user_info.email}</div> : <div></div>}
                                {video ? <div className="text-base">{video?.user_info.follow_count} 人訂閱</div> : <div></div>}
                            </div>
                        </div>

                        <div className="flex">
                            <Button
                                disabled={!isOperate}
                                style={{
                                    backgroundColor: '#D9D9D9',
                                    minHeight: '40px',
                                    borderRadius: '20px',
                                    minWidth: '80px',
                                    ...(isSubscribed ? { backgroundColor: '#a6a6a6' } : {}),
                                }}
                                onClick={() => {
                                    subscribe_onclick();
                                }}
                            >
                                {isSubscribed ? '取消訂閱' : '訂閱'}
                            </Button>

                            <Button
                                disabled={!isOperate}
                                style={{
                                    backgroundColor: '#D9D9D9',
                                    minHeight: '40px',
                                    borderRadius: '20px',
                                    minWidth: '80px',
                                }}
                                onClick={() => {
                                    like_onclick();
                                }}
                            >
                                <div style={{ marginRight: '10px', display: 'flex' }}>
                                    <img src={heart_img} />
                                    <p style={{ padding: '0', margin: '0', marginLeft: '5px' }}>
                                        {isLiked ? '-1' : '+1'}
                                    </p>
                                </div>
                            </Button>
                        </div>

                    </div>

                    <div
                        style={{
                            flex: '1',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                        }}>

                        <Button onClick={()=>{
                            if (_.get(isLogin, 'isLogin', false) == false) {
                                let timerInterval;
                                Swal.fire({
                                    title: "請登入後再下載",
                                    html: "(3秒後自動跳轉)",
                                    timer: 3000,
                                    timerProgressBar: true,
                                    didOpen: () => {
                                        Swal.showLoading();
                                    },
                                    willClose: () => {
                                        clearInterval(timerInterval);
                                    }
                                }).then((result) => {
                                    if (result.dismiss === Swal.DismissReason.timer) {
                                        navigate('/login')
                                    }
                                });
                                return;
                            }
                            console.log(video);
                            if (_.get(video, 'price', 0) == 0) {
                                Swal.fire({ 
                                    title: '錯誤',
                                    text: '免費課程，無法下載!',
                                    icon: 'error',
                                    confirmButtonText: 'OK'
                                })
                                return;
                            }
                            addToShoppingCart(video);
                            navigate('/shopping-cart')
                        }}
                            style={{
                                backgroundColor: '#D9D9D9',
                                minHeight: '40px',
                                borderRadius: '20px',
                                minWidth: '80px',
                            }}
                        >下載課程</Button>

                    </div>
                </div>

                <div style={{ marginTop: '10px', wordWrap: 'break-word' }}>
                    <p>影片描述: {video?.description}</p>
                </div>
                </div>

                {/* comment  */}
                {token ? (
                <>
                    <div className='container mt-3'>
                    <div className=''>
                        <form onSubmit={handleSubmit}>
                            <div className='form-group mb-2'>
                            <label htmlFor='commentTextarea'>Comment:</label>
                            <textarea
                                className='form-control'
                                id='commentTextarea'
                                rows={3}
                                value={comment}
                                onChange={handleChange}
                                placeholder='Write your comments here...'
                                style={{ resize: 'none' }}
                            />
                            </div>
                            <div className='text-end'>
                            <button type='submit' className='btn btn-primary'>
                                Send
                            </button>
                            </div>
                        </form>
                    </div>
                    </div>
                </>
                ) : (
                <></>
                )}

                {/* comment end */}

                {/* list comment */}
                <div className='mt-2'>
                <div className=''>
                    <div className=''>
                    {commentFilm === null || commentFilm.length === 0 ? (
                        <>
                        <div className='flex justify-content-center'>
                        <h1 className='text-center'>No Comment</h1>
                        </div>
                        </>
                    ) : (
                        commentFilm.map((comment: any, i: any) => (
                        <>
                            <div key={i + 1} className='card bg-dark mt-3'>
                            <div className='card-body text-[#ffffff]/[0.8]'>
                                <blockquote className=' mb-0'>
                                <footer className='mb-3 mt-2'>
                                    <div className='row'>
                                    <div className='col'>
                                        <span
                                        className='text-md text-[#ffffff]/[0.7]'
                                        >
                                        {comment.nickname}
                                        </span>
                                    </div>
                                    <div
                                        className='col text-xs text-end text-[#ffffff]/[0.6]'
                                    >
                                        {comment.created_at}
                                    </div>
                                    </div>
                                </footer>
                                <p className='mb-1 text-base text-[#ffffff]/[0.95]'>{comment.body}</p>
                                </blockquote>
                            </div>
                            </div>
                        </>
                        ))
                    )}
                    </div>
                </div>
                </div>
                {/* list comment end */}
            </Content>
            </Layout>
        </>
        }
    />
    );
}

export default VideoPlayPage;

import * as restAPI from '../utils/axiosManager';
import { ViewCountResponse } from '../model/videoInfoModel';

const getViewerVideo = (uuid: string) => {
  return new Promise((resolve, reject) => {
    const url = `api/video_view/${uuid}`;
    restAPI
      .request('get', url, {})
      .then((res: any) => {
        const data = res.data;

        if (data.error !== '') {
          reject(data.error);
        }

        const videoCount = new ViewCountResponse(data.data);

        resolve(videoCount);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

const getViewerVideoCount = (uuid: string) => {
  return new Promise((resolve, reject) => {
    const url = `api/viewer/${uuid}`;
    restAPI
      .request('get', url, {})
      .then((res: any) => {
        const data = res.data;

        if (data.error !== '') {
          reject(data.error);
        }

        const videoCount = new ViewCountResponse(data.data);

        resolve(videoCount);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export { getViewerVideo, getViewerVideoCount };

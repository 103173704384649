import React,{useState,useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { Button, Layout, Menu } from 'antd';
import morld_logo from '../assets/images/morld_logo.png';
import morld_text_img from '../assets/images/login_morld.png';
import { Link, useLocation } from 'react-router-dom';
import IconTint from 'react-icon-tint';
//首頁icon
import sidebar_home_icon from '../assets/images/sidebar_home_icon.png';
//直播icon
// import radar_img from '../assets/images/radar_img.png';
//推薦icon
import sidebar_recommend_icon from '../assets/images/sidebar_recommend_icon.png';
//類別icon
import sidebar_tags_icon from '../assets/images/sidebar_tags_icon.png';
//商城icon
import sidebar_cart_icon from '../assets/images/sidebar_cart_icon.png';
import plan_icon from '../assets/images/plan.png';
//客服icon
import member_service_icon from '../assets/images/member_service.png';
import member_setting from '../assets/images/member_setting.png';
import { setLogin } from '../actions/login';
import HomeIcon from './HomeIcon';
import _ from "lodash";
import '../styles/Sidebar.css';
import { useGetLocaleStr } from '../utils/i18n';

const { Sider } = Layout;

function Sidebar({refMain}) {
  const dispatch = useDispatch();
  const getLocaleStr = useGetLocaleStr();
  const location = useLocation();
  const onClick = (info:any) => {
   
    switch (info.key) {
      case '1':
        console.log('首頁');
        break;
      case '2':
        console.log('推薦');
        
        break;
      case '3':
        console.log('商城');
        window.location.href = '/payment';
        break;
      case '4':
        console.log('類別');
        break;
      case '5':
        console.log('客服');
        break;
      default:
        console.log('It is not Monday or Tuesday.');
    }
  };


  const [selectedKeys, setSelectedKeys] = useState<string[]>(['/']);

  const handleMenuClick = ({ key }: { key: string }) => {
    setSelectedKeys([key]);
  };

  const handleLinkClick = (e:any) => {
      e.preventDefault(); // 阻止默认的导航行为
  };

  useEffect(() => {
    console.log(location.pathname);
  }, []);

  return (
    <div className='flex flex-col h-full bg-[#110E0C]'>
    <Sider className='h-full'
      style={{
      }}
      width={240}
      breakpoint='lg'
      collapsedWidth='0'
    >
      <div style={{ height: '70px' }}>
        <Button
          style={{ height: '100%', width: '100%', margin: '0', padding: '0', textDecoration: "none" }}
          href='/member_main'
          className='title'
          type='text'
        >
          <img src={morld_logo} alt='morld_logo' />
          <img src={morld_text_img} alt='morld' />
        </Button>
      </div>
      <Menu
        mode="inline"
        className="custom-menu"
        style={{background: "#110E0C"}}
      >
        <Menu.Item key="1">
          <Link to="/member_main" className="custom-link flex gap-2">
            <div className={"flex items-center w-[24px] object-scale-down max-h-full drop-shadow-md rounded-md " + (location.pathname.includes("member_main") ? "text-[#ffffff]" : "")}>
              {location.pathname.includes("member_main") && <IconTint color='#ffffff' src={sidebar_home_icon} maxWidth={24} maxHeight={24} />}
              {!location.pathname.includes("member_main") && <img src={sidebar_home_icon} width={24} height={24} />}
            </div>
            <div className={" "+(location.pathname.includes("member_main") ? "text-[#fff]" : "gradient-text")}>{getLocaleStr("title-home")}</div>
          </Link>
        </Menu.Item>

        <Menu.Item key="3" className='absolute bottom019'>
          <Link to="/payment" className="custom-link flex gap-2">
            <div className={"flex items-center w-[24px] object-scale-down max-h-full drop-shadow-md rounded-md " + (location.pathname.includes("payment") ? "text-[#ffffff]" : "")}>
              {location.pathname.includes("payment") && <IconTint color='#ffffff' src={plan_icon} maxWidth={24} maxHeight={24} />}
              {!location.pathname.includes("payment") && <img src={plan_icon} width={24} height={24} />}
            </div>
            <div className={" "+(location.pathname.includes("payment") ? "text-[#fff]" : "gradient-text")}>{getLocaleStr("title-ecommerce-plan")}</div>
          </Link>
        </Menu.Item>

      </Menu>
    </Sider>
    </div>
  );
}

Sidebar.defaultProps = {
  refMain: "Rahul"
};

export default Sidebar;

import React, { useEffect, useState } from 'react';
import { Layout, message } from 'antd';
import ReactPlayer from 'react-player';

import MyHeader from '../../components/header/Header';
import pre_home_logo from '../../assets/images/pre_home_morld_logo.png';

const { Content } = Layout;

function IntroVideo() {
  const [messageApi, contextHolder] = message.useMessage();

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Content
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: "center",
          width: '100vw',
          padding: '20px',
          background: '#000000'
        }}
      >
        <div className=' text-3xl w-full'>
          <div className='gradient-text w-fit'>How to play ?</div>
        </div>
        <iframe
          width="368"
          height="655"
          src="https://www.youtube.com/embed/r2lv53fU9Kk"
          title="Morld 操作教學 part 1 - 挑戰功能"
          frameBorder="0"
          allow="accelerometer;autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen>
        </iframe>
      </Content>
    </Layout>
  );
}

export default IntroVideo;
